.demand-container {
    position: relative;
    height: calc(100% - 26px);
    display: flex;

    .demand-graph {
        height: 100%;
        flex-grow: 1;
        flex-shrink: 1;
    }

    .demand-legend {
        flex-grow: 0;
        flex-shrink: 0;
        height: 100%;
    }

    &.report-mode {
        display: grid;
        grid-template-columns: 220px auto;
        grid-template-rows: 30% 70%;

        @media print {
            grid-template-columns: 1000px;
            grid-template-rows: 300px auto;
        }

        .demand-graph {
            height: 100%;
            width: 100%;
            grid-column: 2;
            grid-row: 1;

            @media print {
                width: 1000px;
                grid-column: 1;
                grid-row: 1;
            }
        }

        .demand-legend {
            grid-column: 1;
            grid-row: 1 / span 2;

            @media print {
                display: none;
            }
        }

        .demand-table {
            height: 100%;
            grid-column: 2;
            grid-row: 2;
            overflow: auto;

            @media print {
                grid-column: 1;
                grid-row: 2;
                overflow: visible !important;
            }
        }

        &.no-chart {
            .demand-table {
                grid-row: 1 / span 2;

                @media print {
                    grid-row: 1 / span 2;
                }
            }
        }
    }
}

.demand-legend {    
    background: $white;
    width: 40px;
    transition: width .15s;
    padding: 10px;
    overflow: hidden;
    border-right: 1px solid $grayLight;
    z-index: 1;
    .dark-mode & {    
        background: $grayDarker;
        border-color: $grayDarkest;
        color: $white;
        .btn-toggle, .btn-focus{
            color: $white;
        }
    }
    .btn-toggle {
        padding: 0;
        position: absolute;
        bottom: 10px;
        left: 12px;
        text-align: center;
        border: 0px;
        i {
            margin-right: 0;
        }
    }

    .btn-focus {
        padding: 0;
        position: absolute;
        bottom: 40px;
        left: 12px;
        text-align: center;
        border: 0px;
        i {
            margin-right: 0;
        }
    }

    &.active {
        width: 220px;
        background: $grayEvenLighter;
        overflow: auto;
        .dark-mode & {  
            background: $grayDarker;
            border-color: $grayDarkest;
            color: $white;
        }
        .legend-item {
            >span {
                opacity: 1;
            }
        }
    }

    .demand-legend-content {
        overflow-x: hidden;
        overflow-y: auto;
        margin-bottom: 20px;
        height: calc(100% - 65px);
        scrollbar-width: none;

        .report-mode & {
            height: calc(100%);
            margin-bottom: 0px;
        }
        -ms-overflow-style: none;

        /* IE 10+ */
        &::-webkit-scrollbar {
            /* WebKit */
            display: none;
        }
    }

    .legend-item {
        line-height: 20px;
        white-space: nowrap;
        cursor: pointer;
        margin-bottom: 3px;

        &.hidden {
            >div {
                background-color: $grayMid !important;
            }

            span {
                color: $grayMid;
            }
        }

        &.focus {
            >div {
                border-radius: 20px !important;
            }
        }

        &.all-toggle {
            >div {
                background-color: $grayMid !important;
                position: relative;
                left: 0;
                top: 0;
                width: 16px;
                height: 16px;
                border-radius: 3px !important;

                &:after {
                    position: absolute;
                    background: lighten($grayMid, 15%);
                    content: ' ';
                    width: 100%;
                    height: 100%;
                    left: 3px;
                    top: 3px;
                    border-radius: 3px !important;
                }
            }
        }

        >div {
            display: inline-block;
            width: 18px;
            height: 18px;
            line-height: 20px;
            margin-right: 10px;
            vertical-align: top;
            border-radius: 3px !important;
        }

        >span {
            display: inline-block;
            opacity: 0;
            font-size: 13px;
            line-height: 20px;
            width: 165px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.demand-table {
    padding: 10px 15px 15px 15px;

    .positive {
        &:before {
            font-family: "Font Awesome 6 Pro";
            font-weight: 900;
            content: '\f077';
            padding: 3px;
            border-radius: 3px !important;
            margin-right: 3px;
            background-color: lighten($yellow, 20%);
        }
    }

    .negative {
        &:before {
            font-family: "Font Awesome 6 Pro";
            font-weight: 900;
            content: '\f078';
            padding: 3px;
            border-radius: 3px !important;
            margin-right: 3px;
            background-color: lighten($red, 20%);
        }
    }

    @media print { 
        color: $textColor;
    }
}

.demand-error {
    position: absolute;
    opacity: 1;
    left: 52px;
    top: 8px;
}

.demand-modal {    
    .collapse-section {
        border: 1px solid $grayLight;
        margin-bottom: 20px;

        .alert {
            margin-bottom: 0;
            border: 0;
            border-bottom: 1px solid $grayLight;
            padding: 3px 5px;
            font-weight: bold;
            border-radius: 0;
            cursor: pointer;

            span {
                float: right;
            }
        }

        table {
            margin-bottom: 0;
            border: 0 !important;

            tr {

                td,
                th {
                    border-left: 0 !important;
                    border-right: 0 !important;
                    border-top: 0 !important;
                }

                &:last-child {
                    td {
                        border-bottom: 0;
                    }
                }
            }
        }

        .collapse-section-toolbar{
            background: $grayLightest;
            padding: 3px;
            border-bottom: 1px solid $borderColor;
        }
    }
    &.dark-mode {   
        .collapse-section {
            border-color: $grayDarkest;
            .collapse-section-toolbar{
                background: $grayDark;
                border-color: $grayDarkest;
            }
        }
        .alert {
            border-color: $grayDarkest;
        }
    }
}

.highcharts-credits {
    visibility: hidden;
}

.highcharts-no-data {
    >span {
        @include animate(delayShow, 1s, 1, normal, 0s);
        display: block;        
        font-size: 14px !important;
        color: $red !important;
        font-family: 'Lato', sans-serif !important;
    }
}

.highcharts-xaxis-grid{
    display: none;
}

.highcharts-xaxis{
    .highcharts-tick, .highcharts-axis-line{
        stroke: #e6e6e6;
        .dark-mode &{
            stroke: $grayDarkest;
        }
    }
}

.highcharts-yaxis-grid{
    .highcharts-grid-line{
        stroke: #e6e6e6;
        .dark-mode &{
            stroke: $grayDarkest;
        }
    }
}

.highcharts-plot-line {
    stroke: #e6e6e6;
    .dark-mode &{
        stroke: $grayDarkest;
    }

    &.today{
        stroke: $teal;
    }

    &.milestone{
        stroke: $blue;
    }
}

.highcharts-plot-line-label {
    top: 20px !important;
    font-family: 'Lato', sans-serif !important;
}