.pipeline-designer, .super-pipeline-designer{
    .page-title{
        .change-warning{
            display: inline-block;
            margin-left: 5px;
            color: $orange;
            max-width: 0;
            opacity: 0;
            overflow: hidden;
            vertical-align: middle;
            transition: opacity .25s;
            i{
                margin: 0;
            }
            &.active{
                max-width: 100%;
                opacity: 1;
            }
        }
    }
}

.pipeline-designer{
    .page-title{
        .change-warning{
            display: inline-block;
            margin-left: 5px;
            color: $orange;
            max-width: 0;
            opacity: 0;
            overflow: hidden;
            vertical-align: middle;
            transition: opacity .25s;
            i{
                margin: 0;
            }
            &.active{
                max-width: 100%;
                opacity: 1;
            }
        }
    }

    .designer{ 
        display: flex;
        flex-direction: row; 
        height: calc(100% - 45px);
        overflow: hidden; 
        .dark-mode & {
            background-color: $grayDarker;
        }          
        .step-list{
            flex-basis: 25%;
            padding: 10px;
            height: calc(100% - 50px);
            overflow: auto;
            direction: rtl;
            user-select: none;  
            position: relative;
            > span.text-muted{
                display: block;
                direction: ltr;
            }             
        }
        .step-list-gutter{
            background-color: white;
            border-top: 1px solid $borderColor;
            padding: 10px 10px;
            position: absolute;
            bottom: 0;
            width: 25%;
            direction: ltr;
            display: flex; 
            justify-content: flex-end;
            .dark-mode & {
                background-color: $grayDarker;
                border-color: $grayDarkest;
            } 
            .btn{
                padding: 3px 8px;
            } 
        }
        .details{
            flex-basis: 75%;
            overflow: auto;   
            border-left: 1px solid $borderColor;  
            background-color: white;
            .dark-mode & {
                border-color: $grayDarkest;
                background-color: $grayDarker;
            }                                      
            .step-details{      
                margin: 15px;                         
                border: 1px solid $borderColor;
                background-color: rgba($grayLightest, .5);
                box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, .15);
                .dark-mode & {
                    background-color: rgba($grayDarkest, .25);
                    border-color: $grayDarkest;
                }                      
                            
                .step-header{                                   
                    border-bottom: 1px solid $borderColor;
                    padding: 15px;
                    position: relative;
                    .dark-mode & {
                        border-color: $grayDarkest;
                    }
                    .form-group{
                        width: calc(100% - 50px);
                    }
                    p{
                        margin: 0 0 5px 0;
                        color: $grayMid;
                    }
                    .btn-light{
                        position: absolute;
                        right: 0;
                        top: 0;
                        border: 0;
                        border-radius: 0;
                        color: $grayMid;
                        background-color: transparent;
                        i{
                            margin: 0;
                        }
                        &:hover{
                            background-color: rgba(0, 0, 0, .15);
                        }
                    }
                }

                .step-detail{
                    border-bottom: 1px solid $borderColor;
                    padding: 15px;  
                    h6{
                        font-weight: bold;
                    }   
                    .dark-mode & {
                        border-color: $grayDarkest;
                    }                   
                    > div{
                        .expression-field{
                            margin-top: 3px;
                            border: 1px solid $borderColor;
                            padding: 15px;
                            background-color: $white;
                            border-radius: 3px;
                            .dark-mode &{
                                border: 1px solid $grayDarkest;
                                background-color: $grayDarker;
                            }
                        }

                        .form-text{
                            opacity: .75;                        
                        }
    
                    }
                }

                .step-functions{
                    padding: 15px;
                    display: flex;
                    justify-content: flex-end;
                }

                &.active{
                    .step-detail{
                        display: block;                    
                    }
                }
    
                &.error{
                    border: 1px solid $red !important;
                }
    
                &.disabled{
                    .step-header, .step-detail{
                        opacity: .5;
                    }
                }
            }
            .pipe-details{     
                min-height: 100%;                                               
                > *{
                    padding: 15px;
                    border-bottom: 1px solid $borderColor;
                    .dark-mode & {
                        border-color: $grayDarkest;
                    } 
                }
                
                .pipeline-footer{
                    > *{
                        color: $grayMid;
                        font-size: 13px;
                        margin-bottom: 5px;
                    }
                }

                .pipeline-functions{
                    display: flex;
                    justify-content: flex-end;
                    border-bottom: 0;
                }
                
                .pipeline-requirements{
                    background-color: rgba($yellow, .1);
                    h6{
                        font-weight: bold;
                        color: $gray;
                    }
                }
            }
            .test-details{
                margin: 15px;                         
                border: 1px solid $borderColor;
                background-color: $selectionColor;
                box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, .15);
                .dark-mode & {
                    background-color: rgba($selectionColorAlt, .25);
                    border-color: $grayDarkest;
                }
                &.error{
                    background-color: rgba($red, .05);
                    .dark-mode & {
                        background-color: rgba($red, .25);
                    }
                }         
                .test-header{
                    border-bottom: 1px solid $borderColor;
                    padding: 15px;
                    position: relative;
                    .dark-mode & {
                        border-color: $grayDarkest;
                    }
                    .btn-light{
                        position: absolute;
                        right: 0;
                        top: 0;
                        border: 0;
                        border-radius: 0;
                        color: $grayMid;
                        background-color: transparent;
                        i{
                            margin: 0;
                        }
                        &:hover{
                            background-color: rgba(0, 0, 0, .15);
                        }
                    }
                }

                .test-body{
                    padding: 15px;                
                    .result-data{
                        border: 1px solid $borderColor;
                        margin-bottom: 10px;
                        background-color: $white;
                        position: relative;
                        overflow: auto;                    
                        .dark-mode &{
                            background-color: $grayDarker;
                            border-color: $grayDarkest;
                        }
                        label{
                            padding: 10px;
                            margin-bottom: 0;
                            display: block;   
                            font-weight: bold; 
                            cursor: pointer;                        
                        }
                        .btn{
                            position: absolute;
                            right: 10px;
                            top: 10px;
                            padding: 1px 5px;
                        }
                        .collapse{
                            > div{
                                max-height: 351px;
                                overflow: auto;
                            }
                            .result-data-table{
                                margin: 0;
                            }
                        }
                        &.error{
                            border-color: $red;
                            > label{
                                color: $red;
                            }
                        }
                        &.success{
                            border-color: $green;
                            > label{
                                color: darken($green, 20%);
                            }
                        }
                    }
                    .error-block{
                        padding: 15px;
                        border: 1px solid $red;
                        color: $red;
                        background-color: white;
                        .dark-mode &{
                            background-color: $grayDarker;
                        }   
                        h6{
                            font-weight: bold;
                            margin-bottom: 10px;
                        }                     
                    }
                }
            }
        }
    }
}

.step-list-item{
    background-color: $grayLightest;
    border: 1px solid $borderColor;
    border-radius: 5px;
    padding: 5px 10px;
    margin-bottom: 8px;
    cursor: pointer;
    display: flex;       
    align-items: center;  
    direction: ltr; 
    &.drag-item{
        z-index: 10000;
        border-color: $activeSelectionColor;
        .dark &{
            background-color: $grayDark;
            border-color: $activeSelectionColor;
        }
    }
    .dark-mode &, .dark & {
        background-color: $grayDark;
        border-color: $grayDarkest;
        color: white;
    }  
    label{
        margin: 0;
        cursor: pointer;
        flex-grow: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;        
    }
    i.grip{
        cursor: ns-resize; 
        color: $grayMid;
    }
    &.disabled{
        opacity: .5;
    }
    &.active{
        background-color: $selectionColor;
        .dark-mode & {
            background-color: $selectionColorAlt;
        }  
    }
    &.error{
        border-color: $red;
    }
}

.pipeline-source{
    .modal-body{
        > div{
            height: 100%;
            width: 100%;
            .jsoneditor{
                border: 0;
                .jsoneditor-menu, .jsoneditor-statusbar{
                    display: none;
                }
                .jsoneditor-outer.has-main-menu-bar{
                    margin-top: 0;
                    padding-top: 0;
                }
                .jsoneditor-outer.has-status-bar{
                    margin-bottom: 0 !important;
                    padding-bottom: 0 !important;
                }
                .ace_content{
                
                }
            }
        }
    }
}

.pipeline-step-browser{
    .modal-body{
        height: 100%;
        overflow: auto;
        .accordion{
            height: 100%;
            overflow: auto;
            padding: 0 5px 0 0;
            > div.form-group{
                background-color: transparent;
                border: 0;
                padding: 0 0 10px 0;
                margin: 0;
                text-align: right;
                label{
                    margin-bottom: 0;
                }
                input{
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }
                .btn-default:not(.btn-secondary){
                    border: 1px solid $borderColor;
                    border-left: 0px;
                    background: transparent;
                    color: $grayMid;
                    font-size: 21px;
                    line-height: 21px;
                    height: 31px;
                    margin-bottom: 3px;
                    padding: 0.25rem 0.5rem;
                    font-weight: bold;
                    font-family: "Font Awesome 6 Pro"; 
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;   
                    border-left: 0;
                    .dark-mode &{
                        border-color: $grayDarkest; 
                        background: $grayDark;           
                    }
                    &:hover{
                        color: darken($grayMid, 30%);
                    }        
                }
            }
            > div { 
                &.active{
                    .step-palette{
                        max-height: 100000px !important;
                    }
                }
                &.searching{
                    border: 2px dashed $activeSelectionColor;
                }         
                .step-palette{
                    .step-entry{
                        border: 1px solid $borderColor;
                        background-color: $grayLightest;
                        padding: 10px;
                        margin-bottom: 8px;
                        border-radius: 5px;
                        cursor: default;                        
                        .dark &{
                            border: 1px solid $grayDarkest;
                            background-color: $grayDarker;
                        }
                        &:first-child{
                            margin-top: 10px;
                        }
                        &:last-child{
                            margin-bottom: 0;
                        }
                        > div{
                            display: flex;
                            width: 100%;
                            > *{
                                &:first-child{
                                    flex-grow: 1;
                                }
                            }
                            span{
                                line-height: 24px;
                            }
                            .btn{
                                line-height: 20px;
                            }
                        }
                        
                    }
                }
            }
        }
    }
}

.super-pipeline-designer{
    .designer{
        height: calc(100% - 45px);
        display: flex;
        flex-direction: column;
        .list{
            flex-grow: 1;
            padding: 15px;
            overflow: auto;
            padding-bottom: 50px;
        }
        .gutter{
            background-color: white;
            border-top: 1px solid $borderColor;
            padding: 10px 15px;
            position: absolute;
            bottom: 0;
            width: 100%;
            direction: ltr;
            display: flex; 
            justify-content: space-between;
            .dark-mode & {
                background-color: $grayDarker;
                border-color: $grayDarkest;
            } 
            .btn{
                padding: 3px 8px;
            } 
        }
    }
}

.super-step-list-item{
    background-color: $grayLightest;
    border: 1px solid $borderColor;
    border-radius: 5px;
    padding: 5px 10px;
    margin-bottom: 8px;  
    position: relative;
    min-height: 48px;
    cursor: pointer;
    &.header{
        > .super-step-list-item-selections{
            top: 4px;
            > div{
                font-size: 16px;
                font-weight: bold;
                color: $gray;
                text-align: center;
            }
        }
    }
    &.drag-item{
        z-index: 10000;
        border-color: $activeSelectionColor;
        .dark &{
            background-color: $grayDark;
            border-color: $activeSelectionColor;
        }
    }
    .dark-mode &, .dark & {
        background-color: $grayDarker;
        border-color: $grayDarkest;
        color: white;
    }  
    &.disabled{
        > .super-step-list-item-title{
            .passive{
                opacity: .25;
            }
        }
        > .super-step-list-item-selections{
            opacity: .25;
        }
    }
    .super-step-list-item-title{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 3px;
        > span{
            width: 24px;
            height: 24px;
            line-height: 24px;
            margin: 5px 5px 5px 0;
            text-align: center;
            display: block;
            background-color: rgba(0, 0, 0, .25);
            color: white;
            border-radius: 100px;    
            user-select: none;
        }
        .passive{
            flex-grow: 1;
            input{
                height: 36px;
            }
        }  
        .super-step-list-item-grip{
            cursor: ns-resize; 
            color: $grayMidLight;
            padding: 10px 10px 10px 19px;
        } 
        .btns{
            margin-left: 10px;
            .btn {
                padding: 0 5px !important;
                i{
                    margin-right: 0;
                }
            }
        }     
    }
    .super-step-list-item-selections{
        display: flex;
        align-items: center;
        > div{
            flex-basis: 31%;
            font-size: 14px;
            line-height: 24px;
            position: relative;
            &:first-child{
                margin-left: 0;
            }
        }
        > span{
            display: block;
            flex-basis: 3.5%;
            text-align: center;
            font-size: 30px;
            color: rgba($grayMid, .25);
            max-height: 36px;
            line-height: 36px;
        }
    }
}