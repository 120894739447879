.modal{
    .modal-dialog.panel{
        position: absolute;
        width: 75vw;
        max-width: 75vw;
        min-width: 960px;
        margin: 0;
        right: -100vw;
        top: 0;
        bottom: 0;
        transition: right .25s;
        border-radius: 0;
        &.panel-full{
            width: 95vw;
            max-width: 95vw;
        }
        &.panel-fluid{
            width: 75vw;
            min-width: 320px;
            .modal-content{
                .modal-body{
                    min-width: 320px !important;
                }
            }            
        }        
        .modal-header{
            background: $grayEvenLighter;
            border-radius: 0;
            .modal-title{
                position: relative;                
                width: 100%;
                &:has(small){
                    top: -8px;
                }
                small{
                    position: absolute;
                    display: block;
                    font-size: 12px;
                    bottom: -15px;
                }
            }
        }
        .modal-content{
            border: 0px;
            border-radius: 0;
            .modal-body{
                height: calc(100vh - 63px - 71px);
                overflow: auto;
                &.flush{
                    padding: 0;
                }
            }
        }
    }

    &.show{
        .modal-dialog.panel{
            right: 0;
        }
    }

    @media(max-width:991px){ 
        &.show{
            .modal-dialog.panel{
                max-width: 100vw;
                min-width: 100vw;
                width: 100vw;
                margin: 0;
                left: 0;
                top: 0;
                bottom: 0;                 
                .modal-content{
                    border: 0px;
                    border-radius: 0;
                    overflow-x: auto;
                    .modal-body{
                        height: calc(100vh - 63px - 71px);
                        overflow: auto;                        
                    }
                }  
            }         
        }
    }    
}

.help-mode .modal .modal-dialog.panel{
    right: 300px;        
}

.modal-backdrop{
    &.show{
        opacity: .75;
    }
}

.modal-overflow{
    .modal-body{
        max-height: calc(100vh - 200px);
        overflow: auto;
    }
}