.table-tools{
    *:not(.dropdown-menu){
        display: inline-block;
    }
    .btn{
        min-width: 36px;
        @media(max-width:991px){ 
            & > i{
                margin-right: 0;
            }
            & > span{
                display: none;
            }
        }
    }
    .btn-group{
        .btn{
            &.btn-secondary:not(:first-child){
                border-left: 1px solid #5b636b !important;
            }
        }
        &.flush{
            margin-left: 0;
            &.dd-menu{
                .btn{
                    border-top-right-radius: 3px !important;
                    border-bottom-right-radius: 3px !important;   
                    min-width: 20px;
                    i{
                        margin: 0;
                    }
                }
            }
        }
    }
    .btn-default:not(.btn-secondary){
        border: 1px solid $borderColor;
        border-left: 0px;
        background: transparent;
        color: $grayMid;
        font-size: 21px;
        line-height: 21px;
        height: 31px;
        margin-bottom: 1px;
        padding: 0.25rem 0.5rem;
        font-weight: bold;
        font-family: "Font Awesome 6 Pro"; 
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;   
        border-left: 0;
        .dark-mode &{
            border-color: $grayDarkest; 
            background: $grayDark;           
        }
        &:hover{
            color: darken($grayMid, 30%);
        }        
    }
    .form-control{
        width: 200px;
        position: relative;
        top: 1px;
        margin-left: 5px;
        background: $selectionColor;
        &:placeholder-shown{
            background: $white;
        }
    }
    .search-label{
        input{
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            .dark-mode &{
                color: $white;
                background: $grayDark;
                border: 1px solid $grayDarkest;
            }
        }
    }

    .btn-search-clear{
        position: relative;
        &:after{
            content: "\f00d";
            font-weight: 900;
            font-family: "Font Awesome 6 Pro";
            font-size: 14px;
            position: absolute;
            left: 13px;
            top: 5px;
        }
    }

    .btn-columns, .btn-filters, .btn-filters-clear{
        .dark-mode & {
            background-color: $grayDark;
            border-color: $grayDarkest;
            color: $grayMid;
        }

        &.active{
            background-color: $selectionColor !important;
            .dark-mode & {
                background-color: $selectionColorAlt !important;
                border-color: $grayDarkest;
                color: $white;
            }
        }
    }

    .btn-filters{
        i{
            margin: 0;
        }
        &.active{
            &:after{
                content: "\f00d";
                font-weight: 900;
                font-family: "Font Awesome 6 Pro";
                font-size: 14px;
                position: absolute;
                right: 4px;
                top: 10px;
                color: $grayMid;
            }
        }
    }
}

.table-toggle{
    display: flex;
    flex-wrap: wrap;
    .btn{
        flex: 1 0 100%;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        border-radius: 0 !important;
        margin-bottom: 3px;
        padding: 3px;
        margin-left: 0 !important;
    }
}

.react-bootstrap-table{
    overflow: visible;
}

.table, .table-bordered, .table-striped{
    &.table-big-headers{
        thead{
            th{
                white-space: pre-wrap;
                overflow: auto;
                text-overflow: ellipsis;  
            }
        }
    }
    &.table-wide-headers{
        thead{
            th{
                white-space: nowrap;
                overflow: visible;
                text-overflow: none;                 
            }
        }
        tbody{
            tr{
                td{
                    font-size: 14px;     
                    white-space: nowrap;
                    overflow: visible;         
                }
            }        
        }
    } 
    
    &.table-filter{
        thead{
            th{
                .sr-only{
                    display: none;
                }
                > .filter, > .filter-label{
                    display: block;
                    margin: 4px -6px -6px -6px;
                    background-color: $selectionColor !important;
                    .dark-mode & {
                        background-color: $selectionColorAlt !important;                        
                    }
                    label{
                        margin: 0;
                    }
                    input, select{
                        padding: 0 4px;
                        font-size: 14px;
                        line-height: 20px;
                        height: 32px;
                        border: 0px;
                        border-radius: 0px;
                        border-top: 1px solid $borderColor;
                        background-color: $selectionColor !important;
                        &.placeholder-selected{
                            color: $grayMid;
                            option{
                                color: $textColor;
                                .dark-mode &{
                                    color: $white;
                                }
                            }
                        }
                        .dark-mode &{
                            border-color: $gray;
                            background-color: $selectionColorAlt !important; 
                            
                        }
                    }
                }
                .number-filter{
                    display: flex;
                    width: calc(100% + 12px);
                    overflow: hidden;                      
                    .filter-label{
                        width: 20px;
                        select{
                            appearance: none;
                            padding-right: 0;
                            width: 20px;
                        }
                    }
                    > label:not(.filter-label){
                        width: calc(100% - 20px);
                    }                    
                    .number-filter-input{
                        padding-left: 0;
                    }
                }
                .date-filter{
                    display: flex;
                    width: calc(100% + 12px);
                    overflow: hidden;                    
                    .filter-label{
                        width: 20px;
                        select{
                            appearance: none;
                            padding-right: 0;
                            width: 20px;
                        }
                    }
                    > label:not(.filter-label){
                        width: calc(100% - 20px);
                    }
                    .date-filter-input[type="date"]{
                        padding: 0;
                        -webkit-padding-start: 0;
                        &::-webkit-datetime-edit { padding: 0; margin: 0; }
                        &::-webkit-calendar-picker-indicator { padding: 0 4px 0 0; margin: 2px 0 0 0; }
                        &[value=""]{
                            &::-webkit-datetime-edit-text { color: $grayMid;  }
                            &::-webkit-datetime-edit-month-field { color: $grayMid; }
                            &::-webkit-datetime-edit-day-field { color: $grayMid; }
                            &::-webkit-datetime-edit-year-field { color: $grayMid; }
                            &::-webkit-calendar-picker-indicator { color: $grayMid; }                        
                        }
                    }
                }
            }
        }
    }
    
    thead{
        th{
            border-bottom: 1px solid $borderColor;
            font-size: 14px;
            vertical-align: top;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;    
            
            > .filter, > .filter-label{
                display: none;
            }
        }
        .react-bootstrap-table-sort-order{
            font-family: "Font Awesome 6 Pro";
            .caret{
                display: none;
            }
            &:after{
                margin-left: 5px;
                content: '\f0d7'
            }
            &.dropup{
                &:after{
                    content: '\f0d8'
                }
            }
        }
        @media print{
            display: table-row-group !important;
        }
    }
    tbody{
        tr{
            &.selected-row{
                td{
                    background: $selectionColor !important;
                }
            }
            &:nth-of-type(odd){
                background: $grayEvenLighter;
            }
            td{
                font-size: 14px;     
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;           
            }
            td span.highlight{
                background: #edde10;
                .dark-mode &{
                    color: $textColor;
                }
            }
            &.highlight{
                td{
                    background: $destructiveSelectionColor;
                }
            }
            &.dimmed{
                td{
                    color: $grayMid !important;
                }
            }
            &.default{
                td{
                    background-color: rgba($green, .1) !important;
                    .dark-mode & {
                        background-color: darken($green, 45%) !important;
                    }
                }
            }
        }
        td.react-bs-table-no-data{
            text-align: left;
            background: $white;
        }
        @media print{
            display: table-row-group !important;
            color: $textColor !important;
            *{
                color: $textColor !important;
            }
        }
    }
    th, td{
        padding: 6px;
        height: 32px;
        line-height: 21px;
        cursor: default;
        &.readonly{
            cursor: auto;
        }
        &.react-bootstrap-table-editing-cell{
            padding: 0;
            overflow: visible;
            .form-control{
                height: 33px;
                padding: 0 5px;
                font-size: 14px;
                border-radius: 0;
                .dark-mode &{
                    background-color: $grayDarker;
                    color: $white;
                }
            }
            select.form-control{
                padding: 0 2px;
            }
            input[type="checkbox"]{
                margin: 0 10px;
            }
            .alert{
                display: none;
            }
            .single-select{
                height: 33px;
                min-height: 33px;
                max-height: 33px;
                .single-select__control{
                    border-radius: 0;                    
                    height: 33px;
                    min-height: 33px;
                    max-height: 33px;
                    overflow: hidden;
                    .single-select__value-container, .single-select__indicators{
                        padding-top: 0;
                        padding-bottom: 0;
                        position: relative;
                        top: -2px;
                    }
                }
                .single-select__menu{
                    position: absolute;
                    z-index: 1000;
                }
            }
        }
        &.column-reference{
            background-image: $hightlightGradient;
            .dark-mode &{
                background-image: $hightlightGradientAlt;
            }
        }
        &.column-functions{
            background-image: $hightlightGradientAlt2;
            .dark-mode &{
                background-image: $hightlightGradientAlt2;
            }
        }
        &.is-invalid{
            background-color: rgba($red, .25);
        }
        &.column-pre{
            white-space: pre;
        }
        &.column-code{
            font-family: 'Consolas', 'Courier New', Courier, monospace;
            font-weight: 400;
            color: $grayDark;
        }
        &.column-id{
            font-size: 12px;
            text-overflow: unset;
            background-image: $hightlightGradientDesaturated;
            width: 60px;
        }
        &.column-meta{
            font-size: 12px;
            background-image: $hightlightGradientDesaturated;
        }
        &.width-30{
            width: 30px;
            text-align: center;
            padding-right: 0;
            padding-left: 0;
            .user-icon{
                margin-right: 0 !important;
            }
        }        
        &.width-40{
            width: 40px;
            text-align: center;
            padding-right: 0;
            padding-left: 0;
        }
        &.width-50{
            width: 50px;
        }
        &.width-60{
            width: 60px;
        }
        &.width-75{
            width: 75px;
        }  
        &.width-85{
            width: 85px;
        } 
        &.width-90{
            width: 90px;
        }                
        &.width-100{
            width: 100px;
        }
        &.width-125{
            width: 125px;
        }        
        &.width-150{
            width: 150px;
        }  
        &.width-165{
            width: 165px;
        }   
        &.width-175{
            width: 175px;
        }         
        &.width-200{
            width: 200px;
        }
        &.width-250{
            width: 250px;
        }
        &.width-300{
            width: 300px;
        }   
        &.width-400{
            width: 400px;
        }    
        &.width-500{
            width: 500px;
        }              
        .user-icon{
            top: 0px;
        }    
        .row-expansion-style{
            padding: 0;
            .row-detail{
                padding: 5px;
                margin-left: 29px;
                border-left: 1px solid $borderColor;
                .dark-mode &{
                    border-left: 1px solid $grayDarker;
                }
                &.default{
                    background-color: rgba($green, .1) !important;
                    .dark-mode & {
                        background-color: darken($green, 45%) !important;
                    }                 
                }
            }
        }  
        
        &.selection-cell, &.selection-cell-header{
            background-image: $hightlightGradientDesaturated;   
            width: 28px;     
            max-width: 28px;
            input[type="checkbox"]{
                width: 14px;
                height: 14px;
                position: relative;
                top: 2px;
            }
        }
    }

    td{
        &.column-functions{
            padding: 3px;
            .btn{
                font-size: 13px;
                width: 100%;
                padding: 3px 8px;
            }
        }
        &.column-options{
            padding: 3px;
            .btn{
                font-size: 13px;
                padding: 3px 8px;
            }
            .color{
                padding: 0px;
                margin-left: 5px;
                width: 30px;
                border: 0;
                position: relative;
                top: 4px;
            }
        }
    }

    .dark-mode &{
        border-color: $grayDarker;
        thead{
            th{
                background-color: $grayDarker;
                color: $white;
                border-color: lighten($grayDarker, 5%);                
            }
        }
        tbody{
            tr{
                td{
                    background-color: $grayDarker;
                    color: $white;
                    border-color: lighten($grayDarker, 5%);
                }
                &.selected-row{
                    td{
                        background-color: $selectionColorAlt !important;
                    }
                }
                &:nth-of-type(odd){
                    td{
                        background-color: lighten($grayDarker, 3%);
                    }
                }
                &.highlight{
                    td{
                        background: $red;
                    }
                }
                &.dimmed{
                    td{
                        color: darken($grayMid, 20%) !important;
                    }
                }
            }
        }        
    }
}

.table-xs{
    th,td{
        padding: 3px 5px;
        height: auto;
    }
}

.table-audit{
    tr{
        position: relative;
        &.Created{
            background: none !important;
            td.action-type{
                background-color: rgba($teal, .25) !important;
            }
        }
        &.Updated{
            background: none !important;
            td.action-type{
                background-color: rgba($yellow, .25) !important;
            }
        }
        &.Deleted, &.Bulk-Create{
            background: none !important;
            position: relative;
            td.action-type{
                background-color: rgba($red, .25) !important;
            }
            td:nth-child(4){                
                display: block;
                width: calc(95vw - 341px);
                z-index: 100;
                border: 0;  
                border-bottom: 1px solid $borderColor;
                @media(max-width:991px){ 
                    width: calc(100vw - 324px);
                }
            }
            td:nth-child(n+5){
                border: 0;
                @media(max-width:991px){ 
                    width: calc(100vw - 324px);
                }
            }
        }

        &.Bulk-Create{
            td.action-type{
                background-color: rgba($teal, .25) !important;
            }
        }

        td{
            .change-gram{
                margin: -3px -5px;
                padding: 3px;
                display: block;
                height: 27px;
                &.Updated{
                    background-color: rgba($yellow, .25) !important;
                }
                &.Created{
                    background-color: rgba($teal, .25) !important;
                }
                &.Deleted{
                    //background-color: #EECFCF !important;
                }
                &.Bulk-Create{
                    //background-color: #D5F2F1 !important;
                }
            }
        }
    }
}

.table-fixed{
    table-layout: fixed;
}

.react-bootstrap-table-pagination{    
    ul{
        margin-bottom: 0;
    }
    .page-item{
        a{
            padding: 3px 8px;
            .dark-mode &{
                border-color: $grayDarkest;
                background-color: $grayDarker;
            }
        }
        &.active{
            a{
                .dark-mode &{
                    border-color: $grayDarkest;
                    background-color: $blue;
                } 
            }  
        }
    }
}

.table-overflow-container{    
    .react-bootstrap-table{
        border-bottom: 1px solid $borderColor;
        width: calc(100vw - 30px);
        max-height: calc(100vh - 160px);
        min-height: calc(100vh - 160px);
        overflow: auto;
        .dark-mode &{
            border-bottom: 1px solid $grayDarkest;
        }
        .table{
            margin-bottom: 0;
            tbody{
                tr{
                    td{
                        overflow-y: visible;
                    }
                }
            }
        }
        &+ .react-bootstrap-table-pagination{
            margin-top: 10px;
        }
        .help-mode &{
            width: calc(100vw - 330px);
        }
    }

    &.tabs{
        .react-bootstrap-table{
            max-height: calc(100vh - 200px);
            min-height: calc(100vh - 200px);
        }
    }

    &.tabs-functions{
        .react-bootstrap-table{
            max-height: calc(100vh - 410px);
            min-height: calc(100vh - 410px);
        }
    }

    &.tabs-flow{
        .react-bootstrap-table{
            max-height: auto;
            min-height: auto;
        }
    }

    &.with-pre-table{
        .react-bootstrap-table{
            max-height: calc(100vh - 310px);
            min-height: calc(100vh - 310px);
        }
    }
    
    &.table-alert{
        .react-bootstrap-table{
            max-height: calc(100vh - 220px);
            min-height: calc(100vh - 220px);
        }
    }

    &.dialog-table{
        width: 100%;
        .react-bootstrap-table{
            width: calc(100%);
            max-height: calc(100vh - 235px);
            min-height: calc(100vh - 235px);
        }
    }
}

.table-overflow-allowed{
    tbody{
        tr{
            td{
                overflow: visible;
            }
        }
    }
}

.pre-table-content{
    background-color: $grayEvenLighter;
    border: 1px solid $borderColor;
    border-bottom: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin: 0px 0px -46px 0px;
    box-sizing: border-box;
    padding: 15px;
    display: flex;
    align-items: center;
    .dark-mode &{
        background-color: darken($grayDarker, 2%);
        border: 1px solid $grayDarkest;
    }
    > *{
        &:last-child{
            margin-bottom: 30px;
        }
    }
}