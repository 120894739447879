.navbar{
    border-bottom: 2px solid $grayDarkest;    
    border-color: $green;
    box-sizing: border-box;
    padding: 0 1rem;
    height: 50px;
    &.domain{
        border-bottom: 2px solid $grayDarkest;    
        border-color: var(--domain-color);
    }
    &.admin{
        background-color: darken($grayDark, 8%) !important;
        border-bottom: 2px solid $red;    
    }
    .navbar-toggler:focus {
        text-decoration: none;
        outline: none;
    }
    &.navbar-expand-lg{
        z-index: 100;
        position: sticky;
        top: 0;
    }
    .navbar-brand{
        &.small{
            max-width: 58px;
            overflow: hidden;
            margin-left: -15px;
            padding-left: 15px;
            background: rgba(0, 0, 0, .15);
        }
        img{
            width: 172px;
        }
    }
    .domain-header{
        background: rgba(0, 0, 0, .15);
        pointer-events: none;
        color: $white;
        white-space: nowrap;
        text-overflow: ellipsis;
        pointer-events: none;
        overflow: hidden;
        padding: 7px 1rem 11px 5px;        
        line-height: 15px;
        margin-left: -16px;
        a{            
            font-size: 12px;
            opacity: .65;
            pointer-events: all;
            color: $white !important;
            &:hover{
                color: $green !important;
                opacity: 1;
                text-decoration: none;
            }
            &:after{
                font-family: "Font Awesome 6 Pro";    
                font-weight: 600;
                content: '\f105';
                display: inline-block;
                margin-left: 3px;
            }
        }
        div{
            color: $white !important;
            font-size: 16px;
            text-transform: uppercase;
            white-space: nowrap;
            text-overflow: ellipsis;
            pointer-events: none;
            overflow: hidden;
            max-width: 200px;
        }
    }
    .navbar-nav{
        height: 48px;
        .nav-item{            
            .nav-link, .btn-link{
                color: $grayLight;
                padding: 13px .85rem;
                user-select: none;
                &.active{
                    color: $green;
                }
                &.beta{
                    position: relative;
                    &:after{
                        position: absolute;
                        right: 5px;
                        top: 6px;
                        content: 'BETA';
                        font-family: Verdana, Geneva, Tahoma, sans-serif;
                        font-size: 8px;
                        line-height: 6px;
                        letter-spacing: 1px;
                        color: $white;
                        background-color: $rust;
                        padding: 2px;
                        border-radius: 3px;
                    }
                }
                .user-icon{
                    top: -3px;                
                }
                .notification-indicator{
                    position: absolute;
                    right: 17px;
                    top: 6px;
                    font-size: 14px;
                    text-shadow: -1px 1px 1px rgba(0, 0, 0, .5);
                    @include animate(ring, 2s, 2, normal, 1s);
                }
                &:hover{
                    color: $white;
                }
            }  
            &.training-addition{
                max-height: 48px;
                max-width: 100px;
                font-size: 14px;
                line-height: 16px;
                padding: 0;
                background-color: $activeSelectionColor;
                position: relative;                
                a{
                    padding: 8px 12px 8px 12px !important;
                    display: flex;
                    flex-direction: row;
                }
                &:hover{
                    background-color: darken($activeSelectionColor, 10%);
                }
                i{
                    font-size: 24px;
                    margin-right: 5px;
                    padding-top: 4px;
                }
                span{
                    text-align: left;
                }
            }
            &:hover{
                color: $white;
                background: rgba(255, 255, 255, .05);
                img{
                    opacity: 1;
                }
                svg.icon{
                    path{
                        fill: rgba(255, 255, 255, 0.75);
                    }        
                }       
            }        

            .icon{
                margin-right: 3px;
                width: 16px;
                height: 16px;  
                display: none;                              
            }            

            svg.icon{
                position: relative;
                top: -2px;
                path{
                    fill: rgba(255, 255, 255, 0.5);
                }
                &.active{
                    path{
                        fill: $green;
                    }                    
                }
            }

            &.icon-only{
                .nav-link{
                    padding: 13px .85rem 10px .85rem;
                    i{
                        padding: 5px;
                        background-color: rgba(255, 255, 255, .05);                
                        font-size: 16px;
                        border-radius: 3px;
                        min-width: 26px;
                        height: 26px;
                        text-align: center;                        
                    }
                    &.menu{
                        i{
                            &:after{
                                display: inline-block;
                                margin-left: 0.255em;
                                vertical-align: 0.255em;
                                content: "";
                                border-top: 0.3em solid;
                                border-right: 0.3em solid transparent;
                                border-bottom: 0;
                                border-left: 0.3em solid transparent;
                            }
                        }
                    }
                }
            }
        }

        &.tighter{
            .nav-item .nav-link{
                padding: 13px 0.5rem 5px 0.5rem;
            }
        }

        .warning{
            background-color: rgba($yellow, .75);
            color: $white !important;
            opacity: 1 !important;
            .dark-mode &{
                padding-top: 12px;
            }
            .menu.nav-link{
                color: rgba(255, 255, 255, 1);
            }
            &:hover{
                background-color: rgba($yellow, 1);
            }
        }
    }
    .dropdown-menu {
        background: $grayDark;
        margin-top: 0;        
        padding: 2px 0 2px 0;
        min-width: 190px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        top: calc(100% - 2px);
        > .dropdown-item{
            padding: 0;
            &:hover{
                background: rgba(255, 255, 255, .05);
            }
        }
        a.dd-link, .dropdown-item.dd-link{
            display: block;
            padding: 4px 1rem;
            color: $grayLight;
            white-space: nowrap;
            &.active{
                color: $green;
            }
            &:hover{
                color: $white;
                text-decoration: none;
                background: rgba(255, 255, 255, .05);
            }
            i{
                margin-right: 5px;
            }
            &.beta, &.alpha{
                position: relative;
                &:after{
                    position: absolute;
                    right: 3px;
                    top: 3px;
                    content: 'BETA';
                    font-family: Verdana, Geneva, Tahoma, sans-serif;
                    font-size: 8px;
                    line-height: 6px;
                    letter-spacing: 1px;
                    color: $white;
                    background-color: $rust;
                    padding: 2px;
                    border-radius: 3px;
                }
            }
            &.alpha{
                &:after{
                    content: 'ALPHA';
                    background-color: darken($yellow, 13%);
                }
            }

            &.indent{
                
            }
        }
        .dropdown-divider{
            border-top: 1px solid rgba(255, 255, 255, .05);
            padding: 0;
            margin: 2px 0px;
        }
        .dropdown-header{
            font-weight: bold;
            padding: 4px 1rem;
            user-select: none;
            color: $grayMidLight;
        }
        .btn-group{
            padding: 4px 1rem;
            width: 100%;
            .btn{
                padding: 3px 6px;
                font-size: 12px;
                &.active{
                    background: $activeSelectionColor;
                    border-color: darken($activeSelectionColor, 5%);
                }
            }
        }
        .single-select{
            font-size: 12px;
            padding: 4px 1rem;
            .single-select__control{
                height: 30px !important;
                min-height: 30px;
                overflow: hidden;
                background: #6c757d;
                border: 1px solid $grayDarker !important;
                .single-select__value-container{                    
                    .single-select__input {
                        height: 10px !important;                    
                    }
                }
                .single-select__single-value{
                    color: $white;
                }
                &.single-select__control--is-focused{
                    border-color: $grayDarker !important;
                    box-shadow: none;
                }
            }
            .single-select__indicators{
                height: 30px !important;
                .single-select__indicator{
                    padding: 3px;
                }
            }
            .single-select__menu{
                background: #6c757d;
                top: 34px;
                left: 16px;
                max-width: 156px;
                border: 1px solid $grayDarker !important;
                border-top: 0 !important;
            }
        }
    }
    .dropdown{
        &.show{
            background: rgba(255, 255, 255, .05);
        }
        .dropdown-toggle{
            height: 50px;
        }
    }

    @media(max-width:1105px){  
        .navbar-nav{
            > .nav-item{
                > .nav-link{
                    font-size: 15px;
                    padding: 15px 5px;
                }
            }       
        }
    }

    @media(max-width:992px){  
        .domain-header{
            padding: 7px 5px 11px 0px;
            > div{
                font-size: 14px;
            }
        }
        .navbar-nav{
            > .nav-item{
                > .nav-link{
                    font-size: 13px;
                    padding: 15px 5px;
                }
            }
            &.tighter{
                > .nav-item{
                    > .nav-link{
                        padding: 13px 5px 10px 5px;
                    }
                }
            }       
        }        
    }
    @media(max-width:858px){  
        .navbar-nav{
            > .nav-item{
                > .nav-link{
                    font-size: 12px;
                    padding: 15px 3px;
                }
            }    
            > .nav-item.training-addition{
                max-width: 56px;
                > .nav-link{
                    font-size: 12px;
                    padding: 8px 5px 9px 5px !important;
                    i{
                        display: none;
                    }
                    span{
                        text-align: center;
                    }
                }
            }             
        }        
    }    

    @media(max-width: 767px){    
        .warning{
            padding: 5px !important;
            &:after{
                content: ' ' attr(title);
            }
        }

        &.navbar-expand-lg{
        }
        .navbar-brand{
            &.small{
                max-width: 100%;
                margin-left: 0;
                padding-left: 0;
                background: transparent;
            }
        }   
        .navbar-collapse{
            position: relative;
            z-index: 10000;
            background: $grayDark;
            margin: 0px -16px;
            border-bottom: 1px solid $grayDarkest;
        }
        .domain-header{
            margin: 0 -16px;
            padding: 8px 22px;
            > div{
                max-width: 100%;
                font-size: 1em;
            }
        } 
        .navbar-nav{
            display: block;
            height: auto;
            .nav-item{
                display: block;
                padding: 8px;
                .nav-link, .btn-link{
                    padding: 0;
                    font-size: 1em;
                }
                &:hover{
                    background: $grayDark;
                } 
                img{
                    display: none;
                }
            }  
            &.tighter{
                > .nav-item{
                    > .nav-link{
                        padding: 0;
                    }
                }
            } 
            .nav-item.training-addition{
                max-width: 100%;
                > .nav-link{
                    font-size: 1em;
                    padding: 8px !important;
                    i{
                        display: none;
                    }
                    span{
                        text-align: center;
                    }
                }
            }       
        }
        .dropdown{
            &.show{
                background: transparent;
            }
            .dropdown-toggle{
                height: auto;
            }
        }        
        .dropdown-menu {
            border: 0px;
            button:last-child{
                a.dd-link{
                    padding-bottom: 0;
                }
            }
        }
        .dropdown-divider{
            display: none;
        }
        .help-toggle{    
        }
        .user-icon{
            + span.mobile-only{
                padding-left: 5px;
            }
        }
    }
}

.nav-link, .btn, i{
    &.beta, &.alpha{
        position: relative;
        &:after{
            position: absolute;
            right: 5px;
            top: -6px;
            content: 'BETA';
            font-family: Verdana, Geneva, Tahoma, sans-serif;
            font-size: 8px;
            line-height: 6px;
            letter-spacing: 1px;
            color: $white;
            background-color: $rust;
            padding: 2px;
            border-radius: 3px;
        }
    }
    &.alpha{
        &:after{
            content: 'ALPHA';
            letter-spacing: 0px;
            background-color: darken($yellow, 13%);
        }
    }    
} 

.btn{
    &.beta, &.alpha{
        &:after{
            top: -5px;
            right: -5px;
            z-index: 1;
        }
    }
}

i{
    &.beta, &.alpha{
        padding: 0;
        margin: 0;
        display: inline-block;
        &:after{
            position: relative;
            font-size: 12px;
            top: -2px;
            right: 0;
            padding: 2px 5px;
            margin: 0 5px;
        }
    }
}