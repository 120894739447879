.fetch-indicator{
    position: fixed;
    bottom: 15px;
    left: 15px;
    background: $green;
    padding: 10px;
    font-size: 14px;
    z-index: 10000;
    min-width: 200px;
    .spinner{
        margin-bottom: 5px;
        color: $textColor;
        .loader-container{
            width: 20px;
            .loader{
                background: $textColor !important;
                &:after, &:before{
                    background: $textColor !important;
                }
            }
        }
    }
    div.item{
        position: relative;
        margin-left: 20px;
        text-transform: capitalize;
        color: $textColor;
        transition: all 2500ms;
        &.hiding{
            &:before{
                font-family: "Font Awesome 6 Pro";
                content: '\f00c';
                position: absolute;
                left: -18px;
                font-size: 12px;
                line-height: 21px;
                font-weight: 900;
            }
        }
    }
}