@page {
    size: landscape;   /* auto is the initial value */
    margin: 12mm 10mm 10mm 10mm;  /* this affects the margin in the printer settings */
}  

html {
    height: 100%;
    margin: 0;
    padding: 0;
}

* {
    //border-radius: 0 !important;
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}
::-webkit-scrollbar-track {
    background-color: $grayLightest;
}
::-webkit-scrollbar-thumb {
    background-color: $grayMidLight;
    border-radius: 10px;
    border: 2px solid $grayLightest;
}
::-webkit-scrollbar-corner{
    background-color: $grayLightest;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    color: $textColor;
    height: 100%;
    @media print{
        min-height: 0px !important; 
        height: auto;
        @page { 
            size: letter portrait;
        }
    }
    a {
        color: $linkColor;
        cursor: pointer;
        &:hover {
            text-decoration: underline;
            color: darken($linkColor, 10%);
        }
    }

    #root{
        min-height: 100vh;
        @media print{
            min-height: 0px !important; 
        }
        .page-container{
            min-height: 100vh;
            &.dark-mode{
                background-color: $grayDarker;                
                color: $white;
                .page{
                    background-color: $grayDarker;                
                    color: $white;
                } 
                @media print{
                    background: $white;
                    color: $textColor;
                    max-width: 1000px !important;  
                    min-height: 0px !important; 
                    .page{
                        background: $white;
                        color: $textColor;
                    }                       
                }                  
            }         
            @media print{
                background: $white;
                color: $textColor;
                max-width: 1000px !important;   
                min-height: 0px !important;
                .page{
                    background: $white;
                    color: $textColor;
                }                       
            }               
        }
    }
}

.page-title{
    margin-bottom: 20px;
    .dark-mode &{
        color: $white;
        &.page-title-bar{
            background: $grayDarker;
            border-color: $grayDarkest;
        }
        @media print{
            color: $textColor;
            &.page-title-bar{
                background: $grayEvenLighter;
                border-color: $grayLight;
            }                          
        }          
    }
    h1{
        font-size: 28px;
        margin: 0;
        i{
            margin-right: 8px;
        }
        img{
            width: 24px;
            height: 24px;
            margin-right: 5px;
        }        
    }
    h2{
        font-size: 20px;
        margin: 10px 0;
    }
    &.page-title-bar{
        background: $grayEvenLighter;
        height: 45px;
        margin: -15px -15px 15px -15px;        
        padding: 5px 15px;
        position: relative;
        border-bottom: 1px solid $grayLight;
        .page-visualization &{
            padding: 5px 10px;
        }        
        &.page-title-bar-subtle{
            background-color: transparent;
            border-bottom: 0px solid $grayLight;
            margin: -15px -15px 5px -15px;
        }
        h1{
            margin-bottom: 0;
            font-size: 20px;
            font-weight: 400;
            line-height: 33px;
            display: inline-block;        
        }
        p{
            line-height: 33px;
            display: inline-block;        
            color: $grayMid;
            font-style: italic;
            margin-left: 15px;
        }
        .page-title-children{            
            position: absolute;
            top: 6px;
            right: 7px;
        }
        .btn{
            @media print{
                display: none;
            }
        }
        .btn-tools{
            padding: 4px 8px 5px 8px;
            margin-right: -3px;
            margin-left: 5px;
            opacity: .5;
            font-size: 22px;
            line-height: 24px;
            &:hover{
                opacity: 1;
                box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.05) inset;
            }
            .tools-mode &{
                display: none;
            }
            i{
                margin-right: 0;
                .dark-mode &{
                    color: $white;
                }
            }            
        }
        .btn-group{
            margin-left: 5px;
        }
        .alert{
            padding: 4px 8px;
            font-size: 14px;            
            margin: 0 0 0 5px;
            position: relative;
            top: 1px;
        }
    } 

    &.hide-title-text{
        > h1 {
            display: none;
        }
    }
    
    &.beta, &.alpha{
        h1{
            position: relative;
            &:after{
                position: absolute;
                right: -12px;
                top: -2px;
                content: 'BETA';
                font-family: Verdana, Geneva, Tahoma, sans-serif;
                font-size: 8px;
                line-height: 6px;
                letter-spacing: 1px;
                color: $white;
                background-color: $rust;
                padding: 2px;
                border-radius: 3px;
            }            
        }
    }

    &.alpha{
        h1{
            &:after{
                content: 'ALPHA';
                right: -15px;
                background-color: darken($yellow, 13%);
            }
        }
    }
}

.page{
    margin-left: 0;
    transition: width .25s;
    .dark-mode &{
        //background: $grayDarker;
        color: $white;
    }
    &.page-bg{
        background-image: url(../images/page-bg-trans.png);
        background-size: 100% 100%;        
        .dark-mode &{
            background-image: url(../images/page-bg-trans.png);
        }
    }
    &.no-scroll{
        max-height: calc(100vh - 70px);
        max-width: 100vw;
        overflow: hidden;
    }
    @media print{
        margin-top: 15px;    
    }
    .nav-tabs, .pre-table{
        & + div{
            > .page-title-bar{
                margin: 0 0 0 0;    
                background: transparent;
                border-bottom: 0;
                padding: 5px 0px;
                .dark-mode &{
                    background: transparent;
                    border-color: $grayDarkest;
                }
            }
        }
    }

    &.page-visualization{
        height: calc(100vh - 70px);
        padding-left: 0;
        padding-right: 0;
        .page-title-bar{
            margin: -15px 0px 0 0px;            
        }
    }
}

.page-header{
    border-bottom: 3px solid $green;
    margin: -16px -15px 30px -15px;
    padding: 20px;
    position: relative;
    .bg{
        position: absolute;
        background: $green;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: .5;
    }
    .container{
        position: relative;
        z-index: 1;
        padding: 0 15px;
        display: flex;
        .page-title{
            flex-grow: 1;
            display: inline-block;
            margin-bottom: 0;
            h3{
                margin-bottom: 0;
                white-space: nowrap;
            }
        }
        .nav-tabs{
            flex-grow: 0;
            width: auto;
            display: inline-block;
            border: 0;
            margin-bottom: 0 !important;
            position: relative;
            top: 19px;            
            li.nav-item{
                display: inline-block;
                a.nav-link{
                    padding: 0 16px 8px 16px;
                    border: 0;
                    &.active{
                        background: transparent;
                        border-bottom: 3px solid rgba($white, .5);
                        .dark-mode &{
                            border-bottom: 3px solid rgba($grayDarker, .5);
                        }                        
                    }
                }
            }
        }
    }    
}