.icon-container {
    position: relative;
    display: inline-block;
  }
  
  .button-class {
    padding: 10px 20px;
    font-size: 16px;
  }
  
  .notification-badge {
    position: absolute;
    top: -10px;
    right: -10px; 
    background-color: rgb(209, 6, 6);
    color: white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
  }
  .notification-badge1 {
    position: absolute;
    top: -10px;
    right: -3px; 
    background-color: rgb(209, 6, 6);
    color: white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
  }
  .notification-badge1{
    span {
        color: white;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-style: normal;
      }
  }
  .notification-badge2 {
    position: absolute;
    top: -14px;
    right: -10px; 
    background-color: rgb(209, 6, 6);
    color: white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
  }
  .notification-badge2{
    span {
        color: white;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-style: normal;
      }
  }
.page-dashboard{
    height: calc(100vh - 50px);
    .page-header{
        border-color: var(--domain-color);
        .nav-link{
            border-color: var(--domain-color) !important;
        }
    }

    > .container{
        height: calc(100% - 110px);
        overflow: auto;
    }

    #reportContainer{        
        width: 100%;
        height: 100%;
        iframe{
            border: 1px solid $borderColor;
        }
    }

    .report-listing{    
        padding: 0px;
        h5{
            margin-bottom: 15px;        
            display: none;
        }
        ul{
            margin: 0;
            padding: 0;
            list-style: none;
            li{
                margin-bottom: 10px;                
                margin-right: 1%;
                background-size: 200% auto;
                background-position: right center;
                border: 0; 
                position: relative;     
                background-color: $grayLight; 
                border-radius: 5px;         
                > a{
                    display: block;
                    color: $textColor; 
                    padding: 10px;
                    transition: all .25s;
                    border-radius: 5px;
                    i{
                        color: var(--domain-color);
                        margin-right: 5px;
                        transition: all .25s;
                    }
                    span{
                        display: block;
                        font-size: small;
                    }
                    &:hover{
                        text-decoration: none;
                        background-color: var(--domain-color);
                        color: $white;
                        i{
                            color: $white;
                        }
                    }
                }
                &.private{
                    &:before{
                        position: absolute;
                        font-family: "Font Awesome 6 Pro";    
                        font-weight: 900;
                        content: '\f023';
                        color: $white;
                        right: 5px;
                        top: 5px;
                        width: 20px;
                        height: 20px;
                    }
                }  
                > h6{
                    padding: 10px 10px 5px 10px;
                    i{
                        color: var(--domain-color);
                        margin-right: 5px;
                    }      
                }
                > div {              
                    padding: 0 10px 10px 10px;
                    display: flex;
                    justify-content: space-between;
                    > a{
                        flex: 0 1 32%;
                        text-align: center;
                        color: $textColor;
                        transition: all .25s;
                        background-color: $white;
                        border-radius: 5px;
                        &:hover{
                            text-decoration: none;
                            background-color: var(--domain-color);
                            color: $white;
                        }
                    }
                }
                .dark-mode &{
                    background-color: $grayDark;
                    > a{
                        color: $white;
                    }
                    > div{
                        > a{
                            background-color: $grayDarker;
                            color: $white;
                            &:hover{
                                background-color: var(--domain-color);
                            }
                        }                        
                    }
                }
            }            
        }
    }

    .alert-secondary{
        background-color: $grayMidLight;
        border-color: $grayMidLight;
    }

    .nav-tabs{
        flex-grow: 0;
        width: auto;
        display: inline-block;
        border: 0;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        position: relative;     
        user-select: none;   
        li.nav-item{
            display: inline-block;
            a.nav-link{
                background-color: transparent;
                display: block;
                padding: 0 16px 8px 16px;
                border: 0;
                user-select: none;   
                &.active{
                    border-bottom: 3px solid var(--domain-color);
                    .dark-mode &{
                        border-bottom: 3px solid rgba($grayDarker, .5);
                    }                        
                }
            }
        }
    } 
    

    .widget{
        .dark-mode & {
            background-color: $grayDark;
            border: 1px solid $grayDark;
        }
        .card-body{
            padding: 10px;
        }
        div.pills{
            padding: 0;
            a, span{
                display: inline-block;
                background-color: var(--domain-color);
                color: $white;
                padding: 3px 8px;
                border-radius: 3px;
                margin-bottom: 0px;
                margin-right: 1%;
                font-size: small;
                max-width: 48%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                transition: all .25s;
                b{
                    padding-right: 3px;
                }
                &:hover{
                    background-color: $grayLighter;
                    text-decoration: none;
                    color: $textColor;
                    .dark-mode & {
                        background-color: $grayMid;
                    }
                }
            }
            .overflow-message{
                background-color: $grayMid;
            }
        }        
    }
}