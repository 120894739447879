.expression-modal{
    .expression-modal-content{
        display: flex;
    }
}

.expression-editor{
    .btn-success{
        margin-top: 10px;
    }
    .expression-editor-row{
        display: flex;
        margin-bottom: 5px;
        transition: all .25s;
        &.error{
            .expression{
                border: 1px solid $red;
            }
        }
        .operator{
            display: block;
            width: 75px;            
            text-align: left;
            margin-right: 5px;
            line-height: 16px;
            font-size: 28px;
            padding: 0px 10px;        
            height: 62px;
        }
        span.operator{
            padding: 10px 15px;        
            max-width: 65px;
            height: 60px;
        }
        .expression{
            flex-grow: 1;
            margin-right: 5px;
            font-family: 'Consolas', 'Courier New', Courier, monospace;
            font-weight: 400;
        }
        .btn{
            width: 35px;
            font-size: 24px;
            line-height: 16px;
            height: 62px;
        }
        &:first-child{
            .expression{
                margin-right: 0;
                height: 155px;
            }   
        }
    }

    .option-check{
        font-size: small;
        margin-top: 15px;
        margin-bottom: 0;
    }
}

.expression-variables, .expression-functions{ 
    margin-top: 10px;
    h6{
        font-weight: bold;
    }   
    ul{
        list-style: none;
        margin: 0;
        padding: 0;
        width: 100%;
        li{
            display: block;
            width: 100%;
            border: 1px solid $borderColor;
            border-radius: 5px;
            margin-bottom: 3px;
            padding: 1px 5px;
            height: 28px;
            overflow: hidden;
            background: $grayLighter;
            .dark-mode &{
                background: darken($grayDark, 8%);
                border: 1px solid $grayDarkest;
            }
            .copyable-value{
                display: block;
                width: 100%;
                > div{
                    width: calc(100% - 20px);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}

.function-ref{
    > div{
        margin: 0;
        padding: 0 0 0 10px;
        .help-body{
            margin: 10px 0 0 0;
            ul li p{
                margin-bottom: 0;
                font-size: 16px;
                a{
                    display: block;
                    text-align: left;
                }
            }
        }              
    }
}

.expression-preview{
    margin-top: 15px;      
    border: 1px solid $borderColor;  
    background: $grayLightest;
    padding: 10px;
    width: 100%;
    overflow-x: auto;
    .dark-mode &{
        border: 1px solid $grayDarkest;  
        background: darken($grayDark, 8%);
    }
    &.error{
        color: $red;
    }
    &.valid{
        color: darken($green, 10%);
    }
}

.expression-tester{
    margin-top: 0px;      
    border: 1px solid $borderColor;  
    border-top: 0;
    background: $selectionColor;
    width: 98%;
    padding: 15px;
    margin: 0 auto;

    .row{        
        margin-bottom: 5px;
        label{
            font-weight: bold;
        }
        &.scope{
            padding-top: 10px;
            .col-sm-12{
                padding-top: 0;
                padding-bottom: 0;
            }
            label.col-sm-12{
                padding-bottom: 5px;                
            }
        }
        &:last-child{
            margin-bottom: 0;
        }
    }
    .dark-mode &{
        border: 1px solid $grayDarkest;  
        border-top: 0;
        background: darken($selectionColorAlt, 10%);
        color: $white;
    }
    dl {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        width: 100%;      /* set the container width*/
        overflow: visible;
        margin: 0;
        dt {
            flex: 0 0 25%;
            text-overflow: ellipsis;
            overflow: hidden;  
            font-weight: normal;      
        }
        dd {
            flex:0 0 75%;
            padding-left: 8px;
            margin-left: auto;
            text-align: left;
            text-overflow: ellipsis;
            overflow: hidden;
            margin-bottom: 2px;
            span.undefined{
                color: red;
            }
        }
    }
}

.expression-meta{
    margin-top: 0px;      
    border: 1px solid $borderColor;  
    border-top: 0;
    background: $selectionColor;
    width: 98%;
    padding: 15px;
    margin: 0 auto;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    font-size: small;
    text-align: center;
    .dark-mode &{
        background: darken($selectionColorAlt, 10%);
        border-color: $grayDarkest;  
    }
    > div{
        margin-top: 10px;
        display: flex;
        > span{
            display: block;
            flex-grow: 1;
            margin-right: 10px;
            text-align: center;
        }
    }
}

.accordion{
    .dark-mode &{
        background-color: $grayDarker;
    }
    > div{
        border: 1px solid $borderColor;
        background-color: $grayLightest;
        padding: 10px;
        margin-bottom: 5px;
        .dark-mode &{
            border: 1px solid $grayDarkest;
            background-color: $grayDarker;
        }
        > h5{
            font-size: 1em;
            margin: -10px;
            padding: 10px;
            font-weight: bold;            
            cursor: pointer;
            user-select:none;
        }
        > div{            
            max-height: 0;
            overflow: hidden;
        }
        &.active{
            background-color: $white;
            > h5{
                margin-bottom: 0px;
            }
            .dark-mode &{
                border: 1px solid $grayDarkest;
                background-color: $grayDark;
            }
            > div{
                transition: all .25s;
                max-height: 1000px;
                overflow: auto;
            }
        }
    }    
}

.expression2-modal{
    .modal-header{
        .btn-color-mode{
            position: relative;
            top: -4px;
            background-color: transparent;
            border: transparent;
            color: inherit;
            opacity: .5;
            i{
                font-size: .75em;
                margin: 0;
            }
            &:hover{
                opacity: 1;
            }
        }
    }
    .modal-body{
        padding: 0;
        .source-editor{
            height: 100% !important;
            display: flex;
            position: relative;
            &.invalid{
                &:after{
                    content: '';
                    position: absolute;
                    pointer-events: none;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    border: 2px solid $red;
                    z-index: 10;
                }
            }
            .ace_editor{
                height: 100% !important;
                resize: none;
                flex: 1 0 calc(100% - 40px);  
                line-height: 22px;       
            }    
            .ace_gutter{
                border-right: 1px solid $borderColor;  
            }  
            .source-editor-tools{
                flex: 0 0 40px;
                width: 40px;
                border-left: 1px solid $borderColor;
                background-color: #f0f0f0;
                position: relative;     
                button{
                    background: transparent;
                    border: 0;
                    width: 100%;
                    min-height: 40px;
                    line-height: 40px;
                    opacity: .5;
                    &.active{
                        background-color: rgba($activeSelectionColor, .15);
                        opacity: 1;
                    }
                    &:hover{
                        background-color: rgba(0, 0, 0, .15);
                        opacity: 1;
                    }
                }
                .validation-icon{
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    min-height: 40px;
                    line-height: 40px;
                    text-align: center;
                    transition: all .15s;
                    &.valid{
                        color: $green;
                    }
                    &.invalid{
                        background-color: $red;
                        color: $white;
                    }
                }
            }    
        }    

        .source-meta{
            height: 100%;
            .expression-tester{
                height: 100%;
                overflow: auto;
                background-color: transparent;
                border: 0;
                margin: 0;
                padding: 10px 0;
                width: auto;
                .form-group{
                    width: 100%;
                    margin: 0 0 5px 0 !important
                }
                .col-form-label{
                    padding: calc(0.375rem + 1px) 0;
                    font-size: small;
                    font-weight: normal;
                    text-align: right;
                }
                .col-sm-8{
                    padding-right: 8px;
                    padding-left: 8px;
                    font-size: small;
                    * {
                        font-size: small !important;
                    }
                    .single-select__menu-list{
                        max-height: 200px;
                    }
                }
                .symbol{
                    opacity: .75;
                    .form-control{
                        background-color: transparent;
                    }
                }
                hr{
                    margin: 32px 5px 10px 5px;
                    position: relative;
                    &:before{                        
                        content: attr(title) ":";
                        font-size: small;
                        opacity: .75;
                        position: absolute;
                        left: 0px;
                        top: -25px;
                        width: 33.3333%;
                    }
                }
            }
        }
    }    

    &.dark-mode{
        .modal-body{
            .source-editor{
                .ace_gutter{
                    border-right: 1px solid $grayDarkest;  
                }
                .source-editor-tools{
                    border-left: 1px solid $grayDarkest;
                    background-color: #25282c;
                    button{
                        color: white;
                    }
                }  
            }
            .source-meta{
                > div:last-child{
                    border-left: 1px solid $grayDarkest;
                }
            }
        }         
    }
}

.ace_autocomplete{
    z-index: 1200;
    position: absolute;
}

.expression-token-context{
    padding: 10px;
    height: 100%;
    overflow: auto;
    > ul{
        padding: 0;
        margin: 0;
        list-style-type: none;
        > li{
            user-select: none;
            margin-bottom: 1em;
        }
        h6{
            margin: 0 0 .25em 0;
            text-transform: capitalize;
            font-size: .9em;
            &:after{
                content: 's';
            }
        }   
    }
    ul.token-list{
        margin: 0 !important;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        li{
            cursor: pointer;
            list-style: none;
            background-color: rgba(0, 0, 0, .05);
            border-radius: 5px;
            padding: 1px 8px;
            margin: 5px 5px 0 0;
            font-size: small;
            flex: 1 0;
            text-align: center;
            border: 1px solid $borderColor;
            transition: all .15s;
            max-width: 130px;
            &:hover{
                border-color: $activeSelectionColor;
            }
            .dark-mode &{
                border: 1px solid $grayDarkest;
                &:hover{
                    border-color: $activeSelectionColor;
                }
            }                
        }
        &.flow{
            li{
                flex: 0 1;
                white-space: nowrap;
                max-width: initial;
                text-align: left;
                .copyable-value{
                    padding-right: 2em;
                }
            }
        }
    }     
    .help-content{
        &:before{
            content: 'References';        
            margin: 0 0 .25em 0;
        }
        .help-body{
            margin: 0 !important;
            padding: 0 !important;
            ul{
                display: flex;
                flex-wrap: wrap;
                list-style: none;
                margin: 0 !important;
                padding: 0 !important;
                li{
                    flex: 1 0;
                    text-align: center;
                    font-size: small;
                    border-radius: 5px;
                    padding: 1px 8px;
                    margin: 5px 5px 0 0;  
                    border: 1px solid $borderColor;
                    transition: all .15s;
                    max-width: 118px;
                    &:hover{
                        border-color: $activeSelectionColor;
                    }
                    .dark-mode &{
                        border: 1px solid $grayDarkest;
                        &:hover{
                            border-color: $activeSelectionColor;
                        }
                    } 
                    p{
                        margin: 0;
                        white-space: nowrap;
                    }                                      
                }
            }
        }
    }
    .overlay{
        width: 100%;
        height: 100%;
        box-shadow: $mainShadow;
        padding: 15px;
        > div{
            height: 100%;
            overflow-y: auto;
            i{
                color: $grayMid;
                font-style: normal;
                font-size: .9em;
            }
            p{
                margin: .5em 0;
            }
            blockquote{
                background-color: rgba(0, 0, 0, .15);
                border-radius: 5px;
                margin: .5em 0;
                padding: 10px;
                > span{
                    font-size: small;
                    display: block;
                    margin: 0 0 .5em 0;
                    font-weight: bold;
                    .btn-link{
                        padding: 0 2px 0 5px;
                    }
                }
                .ace_editor{
                    border: 1px solid $borderColor;
                    .dark-mode &{
                        border: 1px solid $grayDarkest;
                    }
                    .ace_bracket{
                        border: none !important;
                    }
                    .ace_content{
                        padding: .25em;
                    }
                }
            }
        }
    }
}