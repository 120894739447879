.spinner{
    height: 30px; 

    .loader-container{
        display: inline-block;
        width: 35px;
        height: 100%;
        position: relative;

        .loader,
        .loader:before,
        .loader:after {
            background: $textColor;
            animation: load1 1s infinite ease-in-out;
            width: 20%;
            height: 80%;
            box-sizing: border-box;
            display: inline-block;
            .dark-mode & {
                background: $white;
            }
        }

        .loader {
            color: $textColor;
            text-indent: -9999em;
            position: absolute;
            left: 50%;
            top: 50%;
            font-size: 11px;        
            transform: translateY(-45%) translateX(-50%);
            animation-delay: -0.16s;
            .dark-mode & {
                background: $white;
            }
        }

        .loader:before,
        .loader:after {
            position: absolute;
            transform: translateY(-45%);
            left: 50%;
            top: 50%;
            content: '';
            height: 100%;
            width: 100%;
        }

        .loader:before {
            left: -120%;
            animation-delay: -0.32s;
        }

        .loader:after {
            left: 120%;
        }
        @keyframes load1 {
            0%,
            80%,
            100% {
                box-shadow: 0 0;
                height: 30%;
            }
            40% {
                box-shadow: 0 -3px;
                height: 90%;
            }
        }
    }

    > span{
        font-size: 20px;
        font-weight: bold;
        display: inline-block;
        vertical-align: top;
    }

    @-webkit-keyframes rotate { 100% { -webkit-transform: rotate(360deg) }}
    @keyframes rotate {
        100% {
            transform: rotate(360deg);
            -webkit-transform: rotate(360deg);
        }
    }

    @-webkit-keyframes bounce {
        0%, 100% { -webkit-transform: scale(0.0) }
        50% { -webkit-transform: scale(1.0) }
    }

    @keyframes bounce {
        0%, 100% {
            transform: scale(0.0);
            -webkit-transform: scale(0.0);
        } 50% {
            transform: scale(1.0);
            -webkit-transform: scale(1.0);
        }
    }

    &.small{
        height: 20px;
        .loader-container{
            width: 30px;
        }
        > span{
            font-size: 16px;
            line-height: 20px;
            font-weight: normal;
            display: inline-block;
            vertical-align: top;
        }
    }
}