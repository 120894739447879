.error-indicator{
    position: fixed;
    bottom: 15px;
    right: 15px;
    background: $red;
    padding: 10px;
    font-size: 14px;
    color: $white;
    z-index: 10000;
    min-width: 200px;
    ul{
        margin: 0;
        margin-left: 4px;
        padding-left: 15px;
        li{
            font-size: 14px;

        }
    }
}
.errorColor{
    color: rgb(202, 60, 60)!important;
}