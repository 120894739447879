$red: #ca3c3c; 
$blue: #3c64ca; 
$teal: #3ccac5; 
$aqua: #3bacd3;
$green: #8fc73d;
$yellow: #edde10; 
$orange: #edb42a;
$rust: #ed8a2c;
$grayDarkest: #111;
$grayDarker: #222;
$grayDark: #343a40;
$gray: #4c555e;
$grayBs: #6c757d;
$grayMid: #999999;
$grayMidLight: #bbbbbb;
$grayLight: #ddd;
$grayLighter: #eeeeee;
$grayLightest: #efefef;
$grayEvenLighter: #f9f9f9;
$white: #fff;
$black: #000;

$textColor: $grayDark;
$linkColor: darken($green, 20%);
$mainNavColor: $gray;
$adminNavColor: $blue;
$selectionColor: lighten($teal, 42%);
$selectionColorAlt: darken($selectionColor, 75%);
$destructiveSelectionColor: lighten($red, 30%);
$activeSelectionColor: darken($selectionColor, 60%);
$borderColor: $grayLight;
$borderColorAlt: $grayMid;
$hightlightGradient: repeating-linear-gradient(0,rgba($teal,.035),rgba($teal,.035));
$hightlightGradientAlt: repeating-linear-gradient(0,rgba($aqua,.035),rgba($aqua,.035));
$hightlightGradientAlt2: repeating-linear-gradient(0,rgba($green,.05),rgba($green,.05));
$hightlightGradientDesaturated: repeating-linear-gradient(0,rgba($grayMid,.05),rgba($grayMid,.05));

$toolsPanelWidth: 300px;
$helpPanelWidth: 300px;

$mainShadow: 3px 3px 10px 3px rgba(0, 0, 0, .15);
$standardCellWidth: 40px;
$standardRowHeight: 36px;

$newTeal: #24bcd4;
$at: rgba($newTeal, .5);
$atDark: rgba($newTeal, .75);

$newYellow: #e8d300;
$under: rgba($newYellow, .5);
$underDark: rgba($newYellow, .75);

$newRed: #e91e63;
$over: rgba($newRed, .60);
$overDark: rgba($newRed, .85);