.btn{    
    border: 1px solid rgba(0, 0, 0, .07);
    &:hover{
        border: 1px solid rgba(0, 0, 0, .20);
    }

    &.btn-success{
        background: $green;
        color: $white;
    }

    &.btn-warning{
        background: $orange;
        border: 1px solid darken($orange, 5%);
        color: $white;
        &:hover{
            background: darken($orange, 5%);
        }
    }

    &.btn-outline-warning{
        border: 1px solid darken($orange, 5%);
        color: darken($orange, 5%);
        &:hover:not(:disabled){
            background: darken($orange, 5%);
            color: $white;
        }        
    }

    &.btn-outline-danger{
        border: 1px solid darken($red, 5%);      
    }  
    
    &.btn-outline-secondary{
        .dark-mode &{
            border-color: #6c757d !important;
        }
    }

    &:focus, &:active, &:active:focus{
        box-shadow: none !important;
    }

    &:not(:disabled):active:focus{
        box-shadow: 0 0 10px 3px rgba(0, 0, 0, .15) inset !important;
    }

    > i{
        margin-right: 4px;
    }

    .img-icon{
        vertical-align: text-bottom;
        margin-right: 3px;
        width: 16px;
        height: 16px;
    }
}

.btn-light{
    border: 1px solid $borderColor;
    .dark-mode &{
        background: $grayMid;
        border: 1px solid $gray;
        &:hover{
            background: $grayMidLight;
        }
    }
}

.btn-group-sep{
    .btn{
        margin-right: 5px;
        &:last-child{
            margin-right: 0;
        }
    }
}

.input-group-append{
    .btn{
        > i{
            margin-right: 0;
        }
    }
    .input-group-text{
        .dark-mode &{
            background: $grayDark;
            border-color: $grayDarkest;
            color: $grayLight;
        }
    }
}

.btn-icon-only{
    > i{
        margin-right: 0;
    }
}

.form-control, .custom-file-input{
    border: 1px solid $borderColor;
    &::placeholder{
        color: $grayMid;
    }
    &:focus{
        outline: none !important;
        border: 1px solid $activeSelectionColor;
        box-shadow: none;
        ~ .custom-file-label{
            outline: none !important;
            border: 1px solid $activeSelectionColor;
            box-shadow: none;
        }
    }
    &.is-invalid{
        background-image: none;
    }
    .dark-mode &{
        border-color: $grayDarkest;
        background-color: $grayDark;
        color: $white;
    }

    &.read-only-plain{
        background-color: transparent;
    }

    &.dummy{
        background-color: rgba(0, 0, 0, .05);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}

.custom-file{
    .custom-file-input{
        cursor: pointer;
    }
    .custom-file-label{
        .dark-mode &{
            border-color: $grayDarkest;
            background-color: $grayDarker;
            color: $white;
            &:after{
                background-color: $grayDark;
                color: $white;
            }
        }
    }
}

.custom-control-input ~ .custom-control-label::before{
    background-color: $white;
    .dark-mode &{
        background-color: $grayDark;
    }
}

.custom-control-input:checked ~ .custom-control-label::before{
    border-color: $activeSelectionColor;
    background-color: $activeSelectionColor;
}

.custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba($activeSelectionColor, 0.25);
}

.form-create{
    .form-group{
        margin-bottom: 10px;
        label{
            font-weight: bold;
            margin-bottom: 3px;
            &.custom-file-label{
                font-weight: normal;
            }
        }
        &.is-invalid{
            .custom-file-label{
                border: 1px solid $red;
            }
            .invalid-feedback{
                display: block;
            }
        }
    }
}

.tools-panel{
    .form-group{
        margin-bottom: 10px;
        label{
            font-weight: bold;
            margin-bottom: 3px;
        }
    }
}

.month-range{
    display: flex;
    position: relative;
    .dark-mode &{
        > div{
            > input{
                background: $grayDark;
                border-color: $grayDarkest;
                color: $white;
            }
        }
        .month-picker{
            .calendar-container{
                background: $grayDark;
                .col_mp{
                    border-color: $grayDarker;
                    &.selected_cell, &:hover {
                        background-color: $activeSelectionColor;
                    }
                }
            }
        }
    }

    &.wide{
        > div{
            width: 50%;
            .month-picker{
                .calendar-container{
                    width: 100%
                }
            }
        }
    }
    &.is-invalid{
        > div{
            > input{
                border: 1px solid $red;
            }
        }
        + .invalid-feedback{
            display: block;
        }
    }
    > div{
        margin-right: 3px;
        > input{
            width: 100%;
            height: calc(2.25rem + 2px);
            padding: 0.375rem 0.75rem;
            font-weight: 400;
            line-height: 1.5;
            color: #495057;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #ced4da;
            border-radius: 0.25rem;
            -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;   
            &:focus{
                outline: none;
                border-color: $activeSelectionColor;
            }         
        }

        .month-picker{
            .calendar-container{
                top: -1px;
                left: 0;
                width: 220px;
                border: 1px solid $activeSelectionColor;
                font-size: 14px;
                .selected_date_mp{
                    color: $activeSelectionColor;
                }
                .col_mp{
                    font-size: 14px;
                    &.selected_cell, &:hover {
                        background-color: $selectionColor;
                        color: $textColor;
                    }
                }
                .arrows_mp{
                    pointer-events: none;
                    visibility: hidden;
                }
            }
        }

        &:last-child{
            margin-right: 0;
            .month-picker{
                .calendar-container{
                    left: auto;
                    right: 0;
                }
            }
        }
    }

    .btn-group{
        margin-bottom: 0 !important;
    }
}

.multi-select, .single-select{
    padding: 0;
    border: 0;
    font-size: 14px;
    height: auto;
    position: relative;
    &.is-invalid{
        .multi-select__control, .single-select__control{
            border: 1px solid $red;
        }
        + .invalid-feedback{
            display: block;
        }
    }
    &.wide{        
        .multi-select__control{
            .multi-select__multi-value{
                max-width: none !important;
            }
        }
    }
    &.fill{        
        .multi-select__control{
            .multi-select__multi-value{
                flex-basis: 100%;
                max-width: none !important;
                .multi-select__multi-value__label{
                    flex-grow: 1;
                    padding: 3px 5px;
                }
            }
        }
    }
    &.list-only{        
        .multi-select__control{
            .multi-select__value-container{
                input{
                    display: none;
                }
            }
        }
    }

    .multi-select__control, .single-select__control{  
        &.multi-select__control--menu-is-open, &.single-select__control--menu-is-open{
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }                   
        &.multi-select__control--is-focused, &.single-select__control--is-focused{
            border-color: $activeSelectionColor !important;
            box-shadow: none;
        }
        .multi-select__placeholder, .single-select__placeholder{
            color: $grayMid;
            margin-left: 5px;
        }
        .multi-select__value-container{
            padding: 2px 0 0px 2px;  
            > div:last-child{
                margin: 0 2px;
                padding: 0;
            }         
        }
        .multi-select__multi-value{
            margin-top: 0;
            margin-left: 0;
            max-width: 100px;
            height: 31px;
            max-height: 31px;           
            .multi-select__multi-value__label{
                padding-left: 3px;
                line-height: 24px;
            }
            .multi-select__multi-value__remove{
                padding: 0 2px;
                cursor: pointer;
                &:hover{
                    background-color: $destructiveSelectionColor;
                    color: $red;
                }
            }       
        }                              
        .multi-select__indicators{
            align-items: flex-start;
        }
        .multi-select__indicator{
            padding: 8px 5px;
            cursor: pointer;
            &.multi-select__clear-indicator:hover{                        
                background-color: $destructiveSelectionColor;
                color: $red;
            }
        }
        .multi-select__input{
            .dark-mode &{
                color: $white;
            }
        }
    }
    .multi-select__menu, .single-select__menu{                
        margin-top: 0;
        border: 1px solid $grayMid !important;
        z-index: 1000;
        .dark-mode &{
            border: 1px solid $grayDarkest !important;
        }
        border-top: 0 !important;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        z-index: 100;
        .multi-select__option, .single-select__option{
            padding: 4px 6px;
            cursor: default;
        }
        .multi-select__option--is-focused{
            background-color: $selectionColor !important;
        }
        .multi-select__group-heading, .single-select__group-heading{
            padding-right: 6px;
            padding-left: 6px;
        }
        .single-select__option--is-selected{
            background-color: $selectionColor;
            color: $grayDark;
            &:hover{
                background-color: $selectionColor !important;
                color: $grayDark;
            }            
            &.single-select__option--is-focused{
                color: $grayDark;
            }
        }
        .single-select__option--is-focused{
            background-color: $selectionColor;
            &:hover{
                background-color: $selectionColor !important;
                color: $grayDark;
            }            
        }              
    }

    .dark-mode &{
        .multi-select__control, .single-select__control{
            background: $grayDark;
            border-color: $grayDarkest;
            color: $white !important;
            &.multi-select__control--is-disabled{
                opacity: .25;
            }
        }
        .single-select__input{
            color: $white !important;
        }
        .multi-select__menu, .single-select__menu{
            background: $grayDark;
            .multi-select__option--is-focused, .single-select__option--is-focused{
                background-color: $selectionColorAlt !important;
                color: $white !important;
            }
        }
        .single-select__option--is-selected{
            background-color: $selectionColorAlt !important;
            color: $white !important;
        }
        .multi-select__multi-value, .single-select__single-value{
            color: $white;
        }

        .multi-select__multi-value{
            background-color: $grayDarker;
            .multi-select__multi-value__label{
                color: $white;
            }
        } 
    }

    .with-availability{
        text-align: left;
        display: flex;
        justify-content: space-between;   
        > label{
            text-align: left;
            align-items: flex-start;
            justify-content: flex-start;            
        }
        > span{
            display: flex;
            flex-grow: 1;
            max-width: 75%;            
            align-items: center;
            > span{
                margin-left: 1px;
                flex-grow: 1;
                height: 10px;
                max-width: 10px;
                justify-content: space-between;                
                background-color: currentColor;
                &.under, &.none{
                    color: darken($under, 10%);
                }
                &.over{
                    color: $over;
                }
                &.at{
                    color: $at;
                }
            }
        }
        
        &.over, &.at {
            color: $red !important;
        }

        &.under, &.none {
            color: darken($green, 15%) !important;
        } 
    } 
}

.inputs-inline{
    .form-check{
        display: inline-block;
        padding-left: 15px;
        margin-right: 11px;
        label{
            font-size: 13px;
            line-height: 13px;
            vertical-align: bottom;
            input{
                margin-right: 0px;
                margin-left: -15px;    
                margin-top: 0;            
            }
        }
    }
}

.form-group{
    .rfip{
        width: 100%;
        padding: 0;
        margin: 0;
        .rfipbtn{
            width: 100%;
            min-height: auto;
            .rfipbtn__current{
                display: block;
                .rfipbtn__icon{
                    border: 0;
                }
                .rfipbtn__del{
                    display: none;
                }
            }
        }
    }
}

input::-webkit-inner-spin-button, input::-webkit-clear-button {
    display: none;
}

input[type="color"]{
    padding: 0px 2px;
}

.form-group.passive{
    margin: 0 0 0 0;
    input, textarea, select{
        background-color: transparent;
        border: 0;
        padding: 0;
        margin: 0;
        display: block;
        &:focus{
            border: 1px solid $activeSelectionColor;
            padding: 5px;
            &::placeholder {
                color: transparent;
            }
        }
        &.title{
            font-weight: bold;  
            font-size: large;  
        }
        &.soft, &::placeholder{
            color: $grayMid;
        }
    }
    &.add-on{     
        display: flex;
        align-items: baseline;
        input, textarea, select{
            display: inline;
            flex-grow: 1;
            flex-basis: 50%;
            &.title + span{
                font-weight: bold;  
                font-size: large; 
            }
        }
        span{
            flex-grow: 1;
            flex-basis: 50%;
            color: $grayMid;
            text-align: right;
        }
    }
}

form.tight{
    .form-group{
        margin-bottom: 5px;
    }
}