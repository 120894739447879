.card-rows{
    margin-top: 5px;
    display: grid;
    width: 100%;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(375px, 1fr));   
    padding-bottom: 20px; 
    max-height: calc(100vh - 115px);
    overflow: auto;
    padding-bottom: 200px;
    &.tabs{
        max-height: calc(100vh - 160px);
    }
    .card{  
        transition: all .25s;
        .card-body{
            padding: 15px 15px 10px 15px;
        }
        .card-title{
            display: flex;
            justify-content: space-between;
            align-items: center;
            >  * {
                line-height: 30px;
                white-space: nowrap;
            }
            .title{
                flex: 1 1 auto;
                margin: 0 5px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-height: 31px;
                cursor: pointer;
                &.form-control{
                    padding: 0 5px;
                    cursor: text;
                }
            }
            &:first-child, &:last-child{
                flex: 0 0 100px !important;
            }
            .icon{
                width: 24px;
                height: 24px;
                margin: 0 0 0 5px;
            }
            > i{
                margin: 0 0 0 5px;
            }

            .btn-group{
                .dropdown-menu{
                    left: 1px !important;
                }
            }
        }
        .card-text{
            .description{
                display: block;
                margin-bottom: 20px;
                cursor: pointer;
                font-size: 14px;
                line-height: 18px;
                &.form-control{
                    margin-bottom: 0;
                    padding: 0 5px;
                    cursor: text;
                    font-size: 14px;
                    line-height: 18px;
                    margin-bottom: 1px;
                }
                &.flush{
                    &.form-control{
                        margin-bottom: 0px;
                    }
                }
            }
        }        
        &.card-full{
            grid-column: 1 / -1;
        }
        &.card-default{
            border: 1px solid $green;
            background-color: rgba($green, .15);
        }
        &.card-highlight{
            background-color: rgba($yellow, .15);
        }
        .card-footer{
            padding: 10px 15px 15px 15px;
        }
        &.search-match{
            opacity: 1 !important;
            display: block !important;
        }
    }
    &.search-mode{      
        //position: relative;  
        //z-index: 100;
        outline-offset: 3px;
        outline: 2px dashed $activeSelectionColor;
        .card{
            display: none;
        }
    }
    .dark-mode &{
        .card{
            background-color: $grayDarkest;
            &.card-default{
                border: 1px solid $green;
                background-color: rgba($green, .02);
            }
            &.card-highlight{
                border: 1px solid rgba($yellow, .15);
                background-color: rgba($yellow, .05);
            }
        }
    }
}

.card{
    .dark-mode &{
        background-color: transparent;
    }
}
.labelText{
  padding: 0;
  margin: 0;
}
.labelDescription{
  font-size: small;
}

.meta-stack{
    font-size: 12px;
    color: $grayBs;
    display: flex;
    justify-content: space-between;
    color: $gray;
    > div{
        color: $grayBs;
        &:nth-child(2){
            text-align: right;
        }
        h5{
            font-size: 12px;
            font-weight: bold;
            text-transform: uppercase;
            color: $grayMidLight;
            padding: 0;
            margin: 0 0 1px 0;
            line-height: 1.5;
        }
        h6{
            font-size: 10px;
            font-weight: bold;
            text-transform: uppercase;
            color: $grayMidLight;
            padding: 0;
            margin: 0 0 1px 0;
            line-height: 1.5;
        } 
    }
}