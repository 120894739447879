.integration-ui{
    &.integration-ui-v1{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        h5{
            margin-bottom: 1em;
        }
        .integrations-items{
            > div{
                margin-bottom: 1.8em;
                text-align: center;
            }
        }
    }
}