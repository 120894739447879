.mobile-only {
    display: none;

    @media(max-width: 767px) {
        display: inline-block;
    }
}

.font-lg{
    font-size: 18px;
}

.font-md{
    font-size: 16px;
}

.font-sm {
    font-size: 14px;
}

.font-xs {
    font-size: 12px;
}

.text-danger{
    color: $red !important;
}

.text-warning{
    color: $rust !important;
}

.text-soft{
    color: $grayMid;
    color: #6a737b !important;
}

.text-muted{
    color: #6a737b !important;
}

.text-capitalize{
    text-transform: capitalize;
}

.btn-hyperlink{
    color: $green;
    padding: 0;
    margin: 0;
    border: 0 !important;
    display: inline;
    line-height: 1em !important;
    vertical-align: baseline;
    text-decoration: none;
    &:hover{
        color: $green;
        text-decoration: underline;
    }
}

.alert-warning{
    background-color: lighten($yellow, 30%);
    border-color:  darken($yellow, 5%);
    color: $textColor;
}

.alert-light{
    border: 1px solid rgba(0, 0, 0, .15);
    .dark-mode &{
        border-color: rgba(255, 255, 255, .15);
        background-color: $grayDarkest;
    }
}

.card-columns {
    &.card-columns-2{
        column-count: 2;
    }
}

.cards-flush{
    .card{
        border: 0px;
        .card-body{
            padding: 0px;
        }
    }
}

.overflow-container {
    max-height: 300px;
    display: block;
    overflow-y: auto;
    border: 1px solid $borderColor;
    .dark-mode &{
        border: 1px solid $grayDarkest;
    }

    .table-bordered {
        margin-bottom: 0;
        border: 0;

        thead,
        tbody {
            tr {

                th,
                td {
                    border-left: 0;
                    border-top: 0;

                    &:last-child {
                        border-right: 0;
                    }
                }
            }
        }

        tbody {
            tr {
                &:last-child {

                    th,
                    td {
                        border-bottom: 0;
                    }
                }
            }
        }
    }
}

.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.copyable-value{
    display: inline-block;
    position: relative;
    >div{
        display: inline-block;
    }
    .btn-link{
        display: inline-block;
        font-size: smaller;    
        padding: 2px;
        position: absolute;
        right: 0;
        opacity: .5;
        text-align: center;
        color: $green;
        &:hover{
            opacity: 1;
        }
        i{
            position: relative;
            margin: 0;
        }
    }

    textarea{
        position: fixed;
        top: 0;
        left: 0;
        width: 2em;
        height: 2em;
        padding: 0;
        border: none;
        outline: none;
        box-shadow: none;
        background: transparent;
        opacity: 0;
    }

    &.copyable-field{
        display: block;
        position: relative;
        width: 100%;
        > div {
            display: block;
            border: 1px solid $borderColor;
            padding: 3px;
            word-break: break-all;
            background: $white;

            .dark-mode &{
                background: $grayDark;
                border: 1px solid $grayDarkest;
            }
        }
        .btn-link{
            opacity: 1;
            position: absolute;
            top: 0;
            right: 0;
            &:hover{
                background: $grayEvenLighter;
            }
        }
    }
}

.status-message{
    position: fixed;
    bottom: 20px;
    right: 20px;    
    width: 300px;
    background: $green;
    padding: 15px;
    font-size: 14px;
    z-index: 10000;
    min-width: 200px;
    opacity: 0;
    z-index: -1000;
    transition: all .25s;
    box-shadow: $mainShadow;
    &.active{
        opacity: 1;
        z-index: 10000;
    }
    &.error{
        background: $red;
        color: white;
    }  
    &.warning{
        background: $yellow;
    } 
    &.subtle{
        top: auto;
        left: auto;
        bottom: 20px;
        right: 20px;
        transform: none;
    }
    &.dialog{
        bottom: auto;
        right: auto;
        top: 50%;
        left: 50%;
        width: 600px;
        transform: translate(-50%, -50%);
        background: $grayLightest;
        box-shadow: 3px 3px 10px 3px rgba(0, 0, 0, .35);
        height: 220px;
        > div{
            width: 100%;
        }
        .dark-mode &{
            background: $grayDark;
            border: 1px solid $grayDarkest;
        }
    }       
    > div{
        width: calc(100% - 30px);
    }
}

.meta-info{
    width: 100%;
    text-align: center;
    > div{
        display: inline-block;
        font-size: 13px;
        margin-right: 10px;
    }
    .badge-pill{
        cursor: default;
    }
}

.meta-reference{
    display: inline-block;
    height: 29px;
    font-size: 16px;
    line-height: 28px !important;
    padding: 0 5px;    
    text-align: center;
    border: 1px solid lighten($teal, 35%);
    background-color: lighten($teal, 40%);
    .dark-mode &{
        border: 1px solid darken($teal, 45%);
        background-color: darken($teal, 40%);
    }
}

dl.inline{
    display: grid;
    grid-template-columns: max-content auto;    
    border: 1px solid $grayLight;
    border-bottom: 0;
    dt{
        grid-column-start: 1;
        border-bottom: 1px solid $grayLight;
        padding: 7px;
    }
    dd{
        grid-column-start: 2;
        border-bottom: 1px solid $grayLight;
        padding: 7px;
        margin: 0;
    }
    .dark-mode &{
        border-color: $grayDark;
        dt, dd{
            border-color: $grayDark;
        }
    }
}

ul.def-list{
    margin: 0;
    padding: 0;
    list-style-type: none;
    li{
        margin-bottom: 5px;
        span{
            font-weight: bold;
        }
    }
}

.btn-domain{
    background-color: var(--domain-color);
    color: rgba($white, .85);
    transition: all .25s;
    border: 0;
    position: relative;
    overflow: hidden;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, .15);
    text-shadow: 2px 1px 1px rgba(0, 0, 0, .5);
    letter-spacing: .5px;
    &:after{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;             
        background-image: linear-gradient(135deg, $yellow 0%, rgba($white, 0) 35%);      
        opacity: 0;
        transition: all .25s;
        z-index: 0;
    }  
    &:hover{
        color: rgba($white, .85);
        &:after{
            opacity: 1;
        }
    }
    &:active{
        color: $white;
    }
}

.menu-button{
    vertical-align: top !important;
    > button {
        border-radius: 0.2rem !important;
        text-align: center;
        background-color: transparent;
        border: 0;
        font-size: 20px;
        line-height: 20px;
        padding: 4px 10px;   
        i{
            margin-right: 0 !important;
            color: $textColor;
        }
        &:hover{
            background-color: rgba(0, 0, 0, .25) !important;
        }
        &.active, &:focus, &:active, &:active:focus{
            background-color: rgba(0, 0, 0, .5) !important;
        }
        &.btn-domain{
            background-color: var(--domain-color);
            border: 1px solid rgba(0, 0, 0, .15);
            border-top-left-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
            text-shadow: 2px 1px 1px rgba(0, 0, 0, .5);
            letter-spacing: .5px;
            &:after{
                background-image: linear-gradient(-135deg, $yellow 0%, rgba($white, 0) 35%);      
            }
            i{
                color: $white;
            } 
            &:hover{
                background-color:  var(--domain-color) !important;    
            }
            &.active, &:focus, &:active, &:active:focus{
                background-color:  var(--domain-color) !important;
            }                     
        }
    }
    &.show{
        > button{
            background-color: rgba(0, 0, 0, .5);
        }
    }
}

.dropdown-menu{
    padding: 5px 0;

    hr, .dropdown-divider{
        margin: 2px 0;
    }

    .dropdown-item{
        padding: 4px 12px;
        &.beta, &.alpha{
            position: relative;
            &:after{
                position: absolute;
                right: 3px;
                top: 3px;
                content: 'BETA';
                font-family: Verdana, Geneva, Tahoma, sans-serif;
                font-size: 8px;
                line-height: 6px;
                letter-spacing: 1px;
                color: $white;
                background-color: $rust;
                padding: 2px;
                border-radius: 3px;
            }
        }
        &.alpha{
            &:after{
                content: 'ALPHA';
                background-color: darken($yellow, 13%);
            }
        }
    }

    .dropdown-item:hover, .dropdown-item:focus {
        outline: none;
        background-color: $selectionColor;
        color: $textColor !important;
    }
    .dropdown-item.active{
        outline: none;
        background-color: $activeSelectionColor;
        color: $white !important;
    }
    .dropdown-divider{
        //border-top: 1px solid rgba(255, 255, 255, .05);
    }
    .dark-mode &{
        background-color: $grayDark;
        .dropdown-item{
            color: $white;
            &.disabled{
                //color: $grayDark !important;
            }
        }
        .dropdown-item:hover, .dropdown-item:focus{
            outline: none;
            background-color: $selectionColorAlt;
            color: $white !important;
        }
        .dropdown-item.active{
            outline: none;
            background-color: $activeSelectionColor;
            color: $white !important;
        }        
        .dropdown-divider{
            border-top: 1px solid rgba(255, 255, 255, .05);
        }
    }
}

.utility-menu{
    width: auto;
    max-width: 50px;
    height: 37px;
    white-space: nowrap;
    padding-top: 0px;
    .btn{
        border-radius: 3px !important;
        padding: 6px 8px;
        i{
            margin: 0;
        }
    }
    .dropdown-menu-right{
        top: 1px !important;
        left: 1px !important;
    }
}

@mixin keyframes($animationName) {
    @keyframes #{$animationName} {
        @content;
    }
}

@mixin animate($name, $duration, $iteration, $direction, $delay) {
    animation-name: $name;
    animation-duration: $duration;
    animation-iteration-count: $iteration;
    animation-direction: $direction;
    animation-delay: $delay;
}

@include keyframes(delayShow){
    0% {
        opacity: 0;
    }
    99%{
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@include keyframes(delayShowFade){
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    99%{
        opacity: .25;
    }
    100% {
        opacity: 1;
    }
}

@include keyframes(ring){
    0% {
        -webkit-transform: rotate(-15deg);
        -ms-transform: rotate(-15deg);
        transform: rotate(-15deg)
    }

    2% {
        -webkit-transform: rotate(15deg);
        -ms-transform: rotate(15deg);
        transform: rotate(15deg)
    }

    4% {
        -webkit-transform: rotate(-18deg);
        -ms-transform: rotate(-18deg);
        transform: rotate(-18deg)
    }

    6% {
        -webkit-transform: rotate(18deg);
        -ms-transform: rotate(18deg);
        transform: rotate(18deg)
    }

    8% {
        -webkit-transform: rotate(-22deg);
        -ms-transform: rotate(-22deg);
        transform: rotate(-22deg)
    }

    10% {
        -webkit-transform: rotate(22deg);
        -ms-transform: rotate(22deg);
        transform: rotate(22deg)
    }

    12% {
        -webkit-transform: rotate(-18deg);
        -ms-transform: rotate(-18deg);
        transform: rotate(-18deg)
    }

    14% {
        -webkit-transform: rotate(18deg);
        -ms-transform: rotate(18deg);
        transform: rotate(18deg)
    }

    16% {
        -webkit-transform: rotate(-12deg);
        -ms-transform: rotate(-12deg);
        transform: rotate(-12deg)
    }

    18% {
        -webkit-transform: rotate(12deg);
        -ms-transform: rotate(12deg);
        transform: rotate(12deg)
    }

    20% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg)
    }
}

.resizable-set{
    .resizer{
        background-color: $white !important;
        border-left: 1px solid $grayLight;
        transition: all .25s;
        &:hover{
            background-color: $selectionColor !important;
        }
        .dark-mode &{
            background-color: $grayDarker !important;
            border-color: $grayDarkest;
            &:hover{
                background-color: darken($activeSelectionColor, 20%) !important;
            }
        }
        &.resizer-column{
            border-top: 1px solid $grayLight;
            .dark-mode &{
                border-color: $grayDarkest;
            }
        }
    }
}