@media print {   
    .printable{        
        .modal{        
            background: $white;    
            .modal-dialog{            
                &.panel{
                    background: $white;
                    padding-top: 30px !important;
                    width: 100vw !important;
                    max-width: 100vw !important;
        
                    .modal-content{
                        .modal-header{
                            .close, .btn{
                                display: none;
                            }
                        }

                        .modal-body{
                            height: calc(100vh - 63px) !important;
                            overflow: visible !important;
                            .collapse{
                                display: block !important;
                            }
                        }

                        .modal-footer{
                            display: none;
                        }
                    }
                }
            }
        }
    }
}