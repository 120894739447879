.page{
    .help-mode &{
        width: calc(100% - #{$helpPanelWidth});
        margin-left: 0;
        @media print{
            width: 100%;
        }
    }
}

.help-panel{
    @media print{
        display: none;
    }
    position: fixed;
    right: -1 * $helpPanelWidth;
    width: 0;    
    max-width: 0;
    top: 50px;
    height: calc(100% - 45px);    
    overflow: hidden;
    transition: right .25s;    
    box-sizing: content-box;
    border-left: 0;
    background-color: $grayLightest;    
    .tab-content{
        height: calc(100% - 50px);
        overflow-y: auto;
        .tab-pane{
            height: 100%;
        }
    }
    .dark-mode &{
        background: $grayDark;        
    }
    .help-mode &{
        right: 0;        
        width: $helpPanelWidth;
        max-width: $helpPanelWidth;
        border-left: 1px solid $grayLight;
    }

    .help-mode.dark-mode &{
        border-left: 1px solid $grayDarkest;
    }

    .help-panel-header{
        position: relative;
        border-bottom: 1px solid $grayLight;
        .dark-mode &{
            border-bottom: 1px solid $grayDarkest;
        }        
        padding: 10px;
        h5{
            margin-bottom: 1px;
        }
        .close{
            position: absolute;
            right: 11px;
            top: 6px;
            padding: 4px 5px 5px 5px;
            &:active:focus{
                outline: none;
                box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.15) inset !important;
            }
            > span:focus{
                outline: none !important;
            }
        }
        .nav-tabs{
            border-bottom: 0;
            .nav-item{
                .nav-link{
                    padding: 0 0;
                    margin-right: 10px;
                    color: $grayMid;
                    h5{
                        margin: 0;
                        user-select: none;
                    }
                    &.active{
                        border-color: transparent;
                        background-color: transparent;
                        color: $textColor;      
                        .dark-mode &{
                            color: $white;
                        }                  
                    }
                    &:hover{
                        box-shadow: none;
                        border-color: transparent;
                        color: darken($green, 10%);  
                    }
                }
            }
        }
    }

    .help-content{
        padding: 10px;

        .help-title{
            h6{
                padding-right: 24px;
            }
        }
    }
}

.help-page{
    .page-title{
        margin-bottom: 15px;
        h3{
            margin-bottom: 0;
        }
    }
    > div{
        margin: 0 auto;
        padding: 20px;        
        .dark-mode &{
            //background: $grayDark;
        }
    }

    &.limit-width{
        > div{ 
            max-width: 960px;
        }
    }

    .list-group{
        margin: 0 auto 0 auto;  
        padding-bottom: 20px;      
        max-width: 960px;
        .dark-mode &{
            .list-group-item{
                background: $grayDarker;
            }
        }
    }
}

.help-content{
    font-size: 14px;     
    @media print{
        padding: 0;
    }      
    .help-title{
        position: relative;        
        h1,h2,h3,h4,h5,h6{
            font-size: 24px;
            margin-bottom: 1px;
        }        
        .popout{
            position: absolute;
            right: 0px;
            top: 0px;
        }
    }
    .help-body{
        margin-top: 15px;
        margin-bottom: 30px; 
        .section{
            page-break-after: always;
            margin-bottom: 40px;
            .section-title{
                font-size: 24px;
                border: 0;
                color: $textColor;
                margin-bottom: 10px;
            }
        }        
        h1{
            font-size: 22px;
            font-weight: normal;
            color: darken($green, 10%);
            margin-bottom: 10px;
            border-bottom: 1px solid $grayMid;
            &[data-toggle]{
                cursor: pointer;
                padding: 2px;    
                position: relative;
                &:after {
                    position: absolute;
                    right: 4px;
                    top: 4px;
                    font-family: "Font Awesome 6 Pro";
                    font-weight: 900;
                    content: '\f107';
                }            
                &.collapsed{
                    &:after {
                        content: '\f104';
                    } 
                }
            }
        }
        h2{
            font-size: 20px;
            color: darken($blue, 0%);
            margin-bottom: 5px;        
            &.faq{
                color: darken($green, 20%);
            }
        }
        h3{
            font-size: 18px;
            margin-bottom: 5px;        
        }
        h4{
            font-size: 16px;
            margin-bottom: 5px;    
        }        
        i{
            &.meta, &.tip, &.note, &.warning{
                display: block;                
            }
            &.meta{
                color: $aqua;                
            }
            &.tip, &.note{
                color: darken($green, 20%);
                font-style: normal;
            }   
            &.warning{
                color: $rust;
            }
        } 
        ul{
            margin: 0 0 1rem 0;
            padding-left: 20px;
            li{

            }
        }
        .content-block{
            transition: all .25s;
            opacity: 1;
            &.collapsed{
                height: 0;
                opacity: 0;
                overflow: hidden;
            }   
        } 
        blockquote{
            background: rgba($grayLightest, .5);
            padding: 10px;
            border-left: 3px solid $grayMid;
            pre{
                margin-bottom: 10px;
                &:last-child{
                    margin-bottom: 0px;
                }
            }
            > p{
                margin-bottom: 0;
            }
        }  
        img{
            max-width: 100%;
        }     
        pre{
            background-color: $grayLightest;
            padding: 8px 10px;
            border-radius: 3px;
            .dark-mode &{
                background-color: $grayDark;
                color: $white;
            }
        } 
        .confluence-embedded-file-wrapper > a{
            display: block;
            margin: 5px 0 10px 0;
            &:before{
                font-family: "Font Awesome 6 Pro";
                font-weight: 400;
                content: '\f35d';
                display: inline-block;
                padding-right: 5px;
            }
        }
    }
    .dark-mode &{
        .ck-content, .ck-toolbar, .ck-list, .ck-dropdown__panel{
            background: $grayDark !important;
            border-color: $grayDarkest !important;            
        }
        .ck-icon, .ck-button__label{
            color: $white;
        }
        .ck-on, .ck-toolbar__separator{
            background: $grayDarkest;
        }
        .ck-button{
            &:active, &:hover{
                background: $grayDarkest !important;
            }
        }
    }
}

.help-listing{
    @media print{
        display: none;
    }
    background: $grayLightest;
    padding: 15px;
    h3{
        font-size: 18px;
        margin-bottom: 15px;
    }
    ol{    
        padding-left: 24px;
        > li{
            margin-bottom: 10px;
            line-height: 20px;    
            &.active{
                a{
                    color: $textColor;
                    font-weight: bold;
                }
            }
        }
    }
    .dark-mode &{
        background: $grayDark; 
        ol{
            > li{
                a{
                    color: lighten($green, 10%);
                }
                &.active{
                    a{
                        color: $white;
                    }
                }
            }  
        }             
    }
}

.no-print{
    @media print{
        display: none;
    }
}
.print-full{
    @media print{
        @page { size: portrait }
        flex: 0 0 100%;
        max-width: 100%;
        > div{
            padding: 0 !important;
        }
    }
}